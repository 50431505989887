import MatrixButton from "@/models/matrixbutton";
import Item from "../item";
import ItemLevel from "../itemlevel";

export default class MatrixButtonViewModel {
    get id(): number | null { return this.button?.id ?? null; }

    get itemId(): number | null {
        if (!this.button?.action_parameter_content) return null;

        const param = JSON.parse(this.button.action_parameter_content);

        return param.item_id;
    }

    set itemId(value: number | null) {
        const actionParameterContent = JSON.stringify({
            item_id: value,
            quantity: 1.0,
            change_description: false,
        });

        if (this.button)
            this.button.action_parameter_content = actionParameterContent;
    }

    get matrixId(): number | null {
        if (!this.button?.action_parameter_content) return null;

        const param = JSON.parse(this.button.action_parameter_content);

        return param.matrix_id;
    }

    set matrixId(value: number | null) {
        const actionParameterContent = JSON.stringify({
            matrix_id: value,
        });

        if (this.button)
            this.button.action_parameter_content = actionParameterContent;
    }

    get color(): number | null {
        return this.button?.font_color ?? this.itemLevel?.color ?? null;
    }

    public button?: MatrixButton;
    public itemLevel?: ItemLevel | null;

    public row_index?: number;
    public column_index?: number;
    
    public item_ask_price: boolean = false;
    public item_ask_weight: boolean = false;
    public item_has_propositions: boolean = false;
    public item_is_menu: boolean = false;
    public item_is_negative: boolean = false;
    public item_is_returnable_deposit: boolean = false;

    constructor(init?: Partial<MatrixButtonViewModel>) {
        Object.assign(this, init);
    }
}