export default class ItemLevel {
    [key: string]: any;
    public uid?: string;
    public id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public order_number?: number;
    public column_number?: number;
    public active?: boolean = true;
    public type?: number = 1;
    public color?: number;
    public item_azure_image1_width?: number = 512;
    public item_azure_image1_height?: number = 512;
    public item_azure_image1_default?: string;
    public azure_image1?: string;
    public click_and_collect_active: boolean = false;
    public kiosk_active: boolean = false;
    public parent_level_id?: number;
    
    // Zephyr properties
    public webshop_id?: number;
    public webshop_active?: boolean = false;

    public entities?: any[] = [];

    constructor(init?: Partial<ItemLevel>) {
        Object.assign(this, init);
    }
}
