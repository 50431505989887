export default class Listing {
    public id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public icon_azure_path?: string;

    constructor(init?: Partial<Listing>) {
        Object.assign(this, init);
    }
}