import Datawarehouse from "@/models/datawarehouse";
import BaseProvider from "./baseprovider"
import DatawarehouseTemplate from "@/models/datawarehousetemplate";
import DatawarehouseViewModel from "@/models/view/datawarehouseviewmodel";
import GlobalCategory from "@/models/globalcategory";

export default class DatawarehouseProvider extends BaseProvider {

    public async fetchDefaultLayout(datawarehouseId: number): Promise<DatawarehouseTemplate> {
        const response = await (await this.getApiV2()).get(`/datawarehouses/${datawarehouseId}/template/default`);

        const template = this.getDatawarehouseTemplate(response.data);

        return template;
    }

    public async saveDefaultLayout(datawarehouseId: number, layoutJson: string): Promise<DatawarehouseTemplate> {
        const response = await (await this.getApiV2()).post(`/datawarehouses/${datawarehouseId}/template/default`, {
            layout_json: layoutJson,
        });

        const template = this.getDatawarehouseTemplate(response.data);

        return template;
    }

    private getDatawarehouseTemplate(data: any): DatawarehouseTemplate {
        const template = new DatawarehouseTemplate(data);

        return template;
    }

    public async getTemplates(datawarehouseId: number): Promise<DatawarehouseTemplate[]> {
        const response = await (await this.getApiV2()).get(`/datawarehouses/${datawarehouseId}/templates`);

        const templates = response.data.map((d: any) => this.getDatawarehouseTemplate(d));

        return templates;
    }

    public async saveTemplate(datawarehouseId: number, template: DatawarehouseTemplate): Promise<DatawarehouseTemplate> {
        const response = await (await this.getApiV2()).post(`/datawarehouses/${datawarehouseId}/templates`, template);

        const t = this.getDatawarehouseTemplate(response.data);

        return t;
    }

    public async deleteTemplate(datawarehouseId: number, templateUid: string): Promise<boolean> {
        const response = await (await this.getApiV2()).delete(`/datawarehouses/${datawarehouseId}/templates/${templateUid}`);

        return true;
    }
    
    public async getDashboardDatawarehouseViewModels(): Promise<DatawarehouseViewModel[]> {
        return this.received(
            await (await this.getApiV2()).get(`/datawarehouses/dashboard`),
            d => this.getDatawarehouseViewModel(d)
        );
    }

    public async getDashboardDatawarehouse(datawarehouseId: number): Promise<Datawarehouse> {
        return this.received(
            await (await this.getApiV2()).get(`/datawarehouse/dashboard/${datawarehouseId}`),
            d => this.getDatawarehouseObject(d),
            false
        )
    }

    private getDatawarehouseObject(data: any): Datawarehouse {
        const dw = new Datawarehouse(data);

        return dw;
    }

    public async getDashboardDatawarehouseResults(datawarehouseId: number, storeIds: number[], fromDate: string, toDate: string): Promise<object> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/datawarehouse/dashboard/results/${datawarehouseId}/${fromDate}/${toDate}${p}`)
        )
    }

    private getDatawarehouseViewModel(data: any): DatawarehouseViewModel {
        return new DatawarehouseViewModel({
            datawarehouse: new Datawarehouse({
                id: data.id,
                name_fr: data.name_fr,
                name_en: data.name_en,
                name_nl: data.name_nl,
            }),
            globalCategory: new GlobalCategory({
                id: data.global_category_id,
                name_fr: data.global_category_name_fr,
                name_en: data.global_category_name_en,
                name_nl: data.global_category_name_nl,
            })
        });
    }
}
