export interface ILayoutJSONField {
    area: string;
    filterValues: string[];
    sortOrder: string;
    dataField: string;
    areaIndex?: number;
}

export interface ILayoutJSON {
    showTotalsPrior: string;
    dataFieldArea: string;
    rowHeaderLayout: string;
    fields: ILayoutJSONField[];
}

export default class DatawarehouseTemplate {
    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public layout_json?: string;
    public is_default: boolean = false;

    constructor(init?: Partial<DatawarehouseTemplate>) {
        Object.assign(this, init);
    }
}