import MeasureUnit from "@/models/measureunit";
import BaseProvider from "./baseprovider"

export default class MeasureUnitProvider extends BaseProvider {
    public async fetchMeasureUnits(payload: {
        entityIds?: number[]
    }): Promise<MeasureUnit[]> {
        let p: String = '';
        p = p.addParams("entityIds", payload.entityIds, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/MeasureUnits${p}`);

        const measureUnits = response.data.map((d: any) => new MeasureUnit(d));

        return measureUnits;
    }
}