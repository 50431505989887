import DocumentStatus from "@/models/documentstatus";
import BaseProvider from "./baseprovider"

export default class DocumentStatusProvider extends BaseProvider {
    public async fetchDocumentStatuses(type: number): Promise<DocumentStatus[]> {
        const response = await (await this.getApiV2()).get(`/DocumentStatuses/Type/${type}`);

        const elements = response.data.map((d: any) => this.getDocumentStatus(d));

        return elements;
    }

    private getDocumentStatus(data: any): DocumentStatus {
        const documentStatus = new DocumentStatus(data);

        return documentStatus;
    }
}