import Listing from "@/models/listing";
import ListingViewModel from "@/models/view/listingviewmodel";
import BaseProvider from "./baseprovider"
import GlobalCategory from "@/models/globalcategory";

export default class ListingProvider extends BaseProvider {
    public async getDashboardListingViewModels(): Promise<ListingViewModel[]> {
        return this.received(
            await (await this.getApiV2()).get(`/listings/dashboard`),
            d => this.getListingViewModel(d)
        );
    }

    public async getDashboardListing(listingId: number): Promise<Listing> {
        return this.received(
            await (await this.getApiV2()).get(`/listings/dashboard/${listingId}`),
            d => this.getListingObject(d),
            false
        )
    }

    public async getDashboardListingResults(listingId: number, storeIds: number[], fromDate: string, toDate: string): Promise<object> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/listings/dashboard/results/${listingId}/${fromDate}/${toDate}${p}`)
        )
    }

    private getListingViewModel(data: any): ListingViewModel {
        return new ListingViewModel({
            listing: new Listing({
                id: data.id,
                name_fr: data.name_fr,
                name_en: data.name_en,
                name_nl: data.name_nl,
                icon_azure_path: data.icon_azure_path,
            }),
            globalCategory: new GlobalCategory({
                id: data.global_category_id,
                name_fr: data.global_category_name_fr,
                name_en: data.global_category_name_en,
                name_nl: data.global_category_name_nl,
            })
        });
    }

    private getListingObject(data: any): Listing {
        const listing = new Listing(data);

        return listing;
    }
}