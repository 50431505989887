import GlobalCategory from "../globalcategory";
import Datawarehouse from "../datawarehouse";

export default class DatawarehouseViewModel {
    public datawarehouse?: Datawarehouse;
    public globalCategory?: GlobalCategory;

    constructor(init?: Partial<DatawarehouseViewModel>) {
        Object.assign(this, init);
    }
}