import Entity from '@/models/entity'
import PagedEntities from '@/models/paged/pagedentities'
import { PagedParams } from '@/models/paged/pagedparams'
import BaseProvider from './baseprovider'
import '../helpers/addParams'

export default class EntityProvider extends BaseProvider {
    public async fetchEntities(): Promise<Entity[]> {
        const response = await (await this.getApiV2()).get('/Entities');
        const entities = response.data.map((d: any) => this.getEntity(d));
        return entities;
    }

    public async fetchPagedEntities(entityIds?: number[], pagedParams?: PagedParams, superAdmin: boolean = false, isProdReady?: boolean): Promise<PagedEntities> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("superAdmin", [superAdmin], false);
        p = p.addParams("isProdReady", [isProdReady], false);
        p = p.addPagedParams(pagedParams);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`entities${p}`);
        return new PagedEntities(response.data);
    }

    public async getEntityFromUid(entityUid: string): Promise<Entity> {
        const response = await (await this.getApiV2()).get(`/entities/${entityUid}`);
        return this.getEntity(response.data);
    }

    public async getEntityFromId(entityId: number): Promise<Entity> {
        const response = await (await this.getApiV2()).get(`/entities/${entityId}`);
        return this.getEntity(response.data);
    }

    public async saveEntity(entity: Entity): Promise<Entity> {
        console.log("entity JSON", JSON.stringify(entity));
        const response = await (await this.getApiV2()).post(`/entities/`, entity);
        const savedEntity = this.getEntity(response.data);
        return savedEntity;
    }

    private getEntity(data: any): Entity {
        return new Entity(data);
    }
}
