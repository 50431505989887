import { createApp } from 'vue'
import App from './App.vue'
import { setupRouter } from './router'
import { createPinia } from 'pinia'
import { authState, authStateKey } from '@/states/auth'
import { globalState, globalStateKey } from '@/states/global'
import { settingState, settingStateKey } from '@/states/setting'
import { countryState, countryStateKey } from '@/states/country'
import { vatState, vatStateKey } from '@/states/vat'
import { paymentTypeState, paymentTypeStateKey } from '@/states/paymentType'
import { currencyState, currencyStateKey } from '@/states/currency'
import { storeMatrixState, storeMatrixStateKey } from '@/states/storeMatrix'
import { SaleStore, saleStoreKey } from '@/stores/sale'
import { measureUnitState, measureUnitStateKey } from '@/states/measureUnit'

// Primevue
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

// Prime components
import Avatar from 'primevue/avatar'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import InputNumber from 'primevue/inputnumber'
import Card from 'primevue/card'
import Message from 'primevue/message'
import InlineMessage from 'primevue/inlinemessage'
import MultiSelect from 'primevue/multiselect'
import Sidebar from 'primevue/sidebar'
import Chart from 'primevue/chart'
import Calendar from 'primevue/calendar'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import Dropdown from 'primevue/dropdown'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Textarea from 'primevue/textarea'
import DataView from 'primevue/dataview'
import Checkbox from 'primevue/checkbox'
import ToggleButton from 'primevue/togglebutton'
import OrderList from 'primevue/orderlist'
import AutoComplete from 'primevue/autocomplete'
import Chip from 'primevue/chip'
import Skeleton from 'primevue/skeleton'
import Toast from 'primevue/toast'
import Password from 'primevue/password'
import FileUpload from 'primevue/fileupload'
import SelectButton from 'primevue/selectbutton'
import Image from 'primevue/image'
import BlockUI from 'primevue/blockui'
import TriStateCheckbox from 'primevue/tristatecheckbox'
import Tag from 'primevue/tag'
import ConfirmDialog from 'primevue/confirmdialog'
import OverlayPanel from 'primevue/overlaypanel'
import ScrollPanel from 'primevue/scrollpanel'
import SplitButton from 'primevue/splitbutton'
import Badge from 'primevue/badge'
import Toolbar from 'primevue/toolbar'
import Breadcrumb from 'primevue/breadcrumb'
import ProgressBar from 'primevue/progressbar'
import Divider from 'primevue/divider'
import Chips from 'primevue/chips'
import Editor from 'primevue/editor'
import PanelMenu from 'primevue/panelmenu'
import ColorPicker from 'primevue/colorpicker'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import InputMask from 'primevue/inputmask'
import RadioButton from 'primevue/radiobutton'
import Listbox from 'primevue/listbox'

// directives
import Tooltip from 'primevue/tooltip'
import BadgeDirective from 'primevue/badgedirective'
import { DomHandler } from 'primevue/utils'
// Prime tables
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup' //optional for column grouping
import Paginator from 'primevue/paginator'
import Row from 'primevue/row'

// Stylesheets
//import 'primevue/resources/themes/saga-blue/theme.css'       //theme
import 'primeflex/primeflex.css'
import 'flag-icons/sass/flag-icons.scss'
import './scss/_theme.scss' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css' //icons'
import './scss/plugandpos.scss'

// Filters
import filters from './helpers/filters'
// Translations
import { i18n } from './i18n'

// register devextreme license key
import config from 'devextreme/core/config'
import { licenseKey } from './devextreme-license'

function getTarget(el: HTMLElement) {
  return DomHandler.hasClass(el, 'p-inputwrapper')
    ? DomHandler.findSingle(el, 'input')
    : el
}

config({ licenseKey })

const router = setupRouter(i18n)
const pinia = createPinia()

const app = createApp(App)

app.config.globalProperties.$filters = filters

//app.provide('usersession', '');

app.use(pinia)
app.use(i18n)
app.use(router)
app.use(authState, authStateKey)
app.use(globalState, globalStateKey)
app.use(settingState, settingStateKey)
app.use(countryState, countryStateKey)
app.use(vatState, vatStateKey)
app.use(measureUnitState, measureUnitStateKey)
app.use(storeMatrixState, storeMatrixStateKey)
app.use(paymentTypeState, paymentTypeStateKey)
app.use(currencyState, currencyStateKey)
app.use(SaleStore.useStore(), saleStoreKey)
app.use(ToastService)
app.use(ConfirmationService)
app.use(PrimeVue, {
  ripple: true,
  inputStyle: 'filled',
})

// components
app.component('Avatar', Avatar)
app.component('Button', Button)
app.component('InputText', InputText)
app.component('InputSwitch', InputSwitch)
app.component('Card', Card)
app.component('Message', Message)
app.component('InlineMessage', InlineMessage)
app.component('Sidebar', Sidebar)
app.component('MultiSelect', MultiSelect)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('ColorPicker', ColorPicker)
app.component('Chart', Chart)
app.component('Calendar', Calendar)
app.component('Dialog', Dialog)
app.component('ProgressSpinner', ProgressSpinner)
app.component('Dropdown', Dropdown)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('InputNumber', InputNumber)
app.component('Textarea', Textarea)
app.component('DataView', DataView)
app.component('Checkbox', Checkbox)
app.component('ToggleButton', ToggleButton)
app.component('OrderList', OrderList)
app.component('AutoComplete', AutoComplete)
app.component('Chip', Chip)
app.component('Paginator', Paginator)
app.component('Skeleton', Skeleton)
app.component('Toast', Toast)
app.component('Password', Password)
app.component('FileUpload', FileUpload)
app.component('SelectButton', SelectButton)
app.component('Image', Image)
app.component('BlockUI', BlockUI)
app.component('Row', Row)
app.component('TriStateCheckbox', TriStateCheckbox)
app.component('Tag', Tag)
app.component('ConfirmDialog', ConfirmDialog)
app.component('OverlayPanel', OverlayPanel)
app.component('TabPanel', TabPanel)
app.component('TabView', TabView)
app.component('Badge', Badge)
app.component('ScrollPanel', ScrollPanel)
app.component('SplitButton', SplitButton)
app.component('Toolbar', Toolbar)
app.component('InputMask', InputMask)
app.component('RadioButton', RadioButton)
app.component('Listbox', Listbox)
app.component('Breadcrumb', Breadcrumb)
app.component('ProgressBar', ProgressBar)
app.component('Divider', Divider)
app.component('Chips', Chips)
app.component('Editor', Editor)
app.component('PanelMenu', PanelMenu)

// directives
// BVE: workaround found on primevue to force top z-index on the v-tooltip directive
// https://github.com/primefaces/primevue/issues/2468
app.directive('tooltip', {
  mounted(el) {
    const target = getTarget(el)
    target.$_ptooltipZIndex ??=
      app?.config?.globalProperties?.$primevue?.config?.zIndex?.tooltip
  },
  ...Tooltip,
})
app.directive('badge', BadgeDirective)

app.mount('#app')

// TODO: BONotification fails managing multiple subsequent shows.
//  This problem disappear with upgrade of PrimeVue but breaks layout.
//  This below error handler catches all 'hard' errors (Black screen with red failure text) and outputs them to console
app.config.errorHandler = function (err: any, vm, info) {
  if (err) console.error('Error: ', err)
  if (vm) console.error('VM: ', vm)
  if (info) console.error('Info: ', info)
}
