export default class CAReportZModel {
    [key: string]: any;
    report_number?: number;
    store_id!: number;
    store_name_fr!: string;
    store_name_nl!: string;
    store_name_en!: string;
    journal_id!: number;
    journal_name!: string;
    training!: boolean;
    opening_date!: Date;
    closing_date?: Date;
    document_pdf_url?: string;
    total_vat_included?: number;
    total_vat_excluded?: number;
    total_vat_included_by_vat_rate_percent_21?: number;
    total_vat_included_by_vat_rate_percent_12?: number;
    total_vat_included_by_vat_rate_percent_6?: number;
    total_vat_included_by_vat_rate_percent_0?: number;
    total_vat_excluded_by_vat_rate_percent_21?: number;
    total_vat_excluded_by_vat_rate_percent_12?: number;
    total_vat_excluded_by_vat_rate_percent_6?: number;
    vat_rate_percent_21?: number;
    vat_rate_percent_12?: number;
    vat_rate_percent_6?: number;
    tickets?: number;
    constructor(init: Partial<CAReportZModel> & Required<{store_id: number, store_name_fr: string, store_name_nl: string, store_name_en: string, journal_id: number, journal_name: string, training: boolean, opening_date: string}>) {
        Object.assign(this, init);
        if (init.opening_date) this.opening_date = new Date(init.opening_date);
    }
}
