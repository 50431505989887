export default class Rank {
    [key: string]: any;
    public id?: number;
    public uid?: string;
    public name_fr?: string;
    public name_nl?: string;
    public name_en?: string;
    public active?: boolean;
    public order_number?: number;
    public entities?: { rank_id?: number, entity_id: number }[];
    constructor(init?: Partial<Rank>) {
        Object.assign(this, init);
    }
}
