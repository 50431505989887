<template>
  <router-link
      v-if='item?.route'
      class="bo-list-item"
      :to="getLink(item.route)">
    <SidebarMenuItemIcon
        v-if='item.icon'
        :color='item?.iconColor'
        :family='item?.iconFamily'
        :icon='item?.icon'
        :sharp='item?.iconSharp'
    />
    <img
        v-else-if="item.iconPath"
        width="28"
        class="image"
        :src="item?.iconPath"/>
    <span
        class="bo-list-item-content"
        v-html="$t(item?.label as string)"/>
  </router-link>
  <li
      v-else
      class="bo-list-item flex align-items-center cursor-pointer text-color px-3 py-2">
    <SidebarMenuItemIcon
        v-if='item?.icon'
        :color='item?.iconColor'
        :family='item.iconFamily'
        :icon='item.icon'
        :sharp='item.iconSharp'
    />
    <span class="bo-list-item-content">{{ item?.label }}</span>
    <span
        v-if="!!item?.items?.length"
        class="pi pi-angle-down text-primary ml-auto"/>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import RouteHelper from '@/helpers/routehelper'
import SidebarMenuItemIcon from '@/components/Layout/Sidebar/SidebarMenuItemIcon.vue'
import { MenuItem } from 'primevue/menuitem'

export default defineComponent({
  components: { SidebarMenuItemIcon },
  props: {
    item: {
      type: Object as PropType<MenuItem>
    }
  },
  setup() {
    const getLink = (path: string) => RouteHelper.getLink(path)

    return {
      getLink
    }
  }
})
</script>
