import ZipCode from "@/models/zipcode";
import BaseProvider from "./baseprovider"

export default class ZipCodeProvider extends BaseProvider {
    public async fetchZipCodes(params: {
        countryIds?: number[],
        countryNames?: string[],
        zipCodes?: string[],
        cities?: string[]
    }): Promise<ZipCode[]> {
        let p: String = '';
        p = p.addParams("countryIds", params.countryIds, true);
        p = p.addParams("countryNames", params.countryNames?.map(c => `"${c}"`), true);
        p = p.addParams("zipCodes", params.zipCodes?.map(c => `"${c}"`), true);
        p = p.addParams("cities", params.cities?.map(c => `"${c}"`), true);
        p = p !== '' ? `?${p}` : '';

        return (await (await this.getApiV2()).get(`/ZipCodes${p}`))
            .data
            .map((d: any) => new ZipCode(d));
    }
}