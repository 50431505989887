export class CustomerSale {
    customer_id?: number;
    customer_uid?: string;
    entity_id?: number;
    store_id?: number;
    store_uid?: string;
    pos_id?: number;
    pos_uid?: string;
    journal_id?: number;
    sale_header_id?: number;
    sale_header_uid?: string;
    date?: Date;
    total_vat_excluded?: number;
    total_vat_included?: number;
    quantity?: number;
    number?: number;

    constructor(init?: Partial<CustomerSale>) {
        Object.assign(this, init);
    }
}