export default class NameSupplier {
    [key: string]: any;

    public uid?: string;
    public id?: number;
    public name_fr?: string;

    constructor(init?: Partial<NameSupplier>) {
        Object.assign(this, init);
    }

    fromJson(init: any): NameSupplier {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch(err) { console.warn(`NameSupplier fromJson conversion warning: ${key} is missing`)}
        }
        return this;
    }
}
