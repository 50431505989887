import Matrix from "@/models/matrix";
import MatrixButtonViewModel from "./matrixbuttonviewmodel";

export default class MatrixViewModel {
    get uid(): string | undefined { return this.matrix?.uid; }
    get nb_rows(): number { return this.matrix?.nb_rows ?? 0; }
    get nb_columns(): number { return this.matrix?.nb_columns ?? 0; }

    public matrix?: Matrix;

    public order_number?: number;

    public buttons?: MatrixButtonViewModel[];

    constructor(init?: Partial<MatrixViewModel>) {
        Object.assign(this, init);
    }
}