import BaseProvider from "./baseprovider";
import Currency from '@/models/currency';

export default class CurrencyProvider extends BaseProvider {
    public async fetchCurrencies(): Promise<Currency[]> {
        const response = await (await this.getApiV2()).get('/public/currencies');
        return response.data.map((d: any) => {
            return this.getCurrency(d);
        });
    }

    public async getCurrencyFromId(currencyId: number): Promise<Currency> {
        const response = await (await this.getApiV2()).get(`/public/currencies/${currencyId}`);

        return this.getCurrency(response.data);
    }

    private getCurrency(data: any): Currency {
        const currency = new Currency();
        currency.fromJson(data);

        return currency;
    }
}
