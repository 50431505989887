import {v4 as uuid} from 'uuid';

export default class StorePrinter {
    [key: string]: any;
    public id?: number;
    public uid: string = uuid();
    public name?: string;
    public store_id?: number;
    public printer_id?: number;
    public zone_id?: number;
    public ip_address?: string;
    public port?: number;
    public task_type_id?: number;
    public one_task_by_item_category1: boolean = false;
    public print_dispatch_service: boolean = true;
    public height_size?: number;
    public width_size?: number;
    public font_type?: number;
    public language?: string;
    public ip_address_rescue_printer?: string;
    public port_rescue_printer?: number;
    public force_rescue_printer?: boolean;
    public printer_model_id?: number;
    public rescue_printer_model_id?: number;
    public paper_width?: number;
    public paper_height?: number;
    public port_type?: string;
    public details_columns_layout?: number;
    public routing_mode?: number;
    public consumption_type?: number;
    public rescue_printer_active?: boolean;

    constructor(init?: Partial<StorePrinter>) {
        Object.assign(this, init);
    }

    public fromJson(init: any): StorePrinter {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch (err) { console.warn(`StorePrinter fromJson conversion warning: ${key} is missing`) }
        }
        return this;
    }

    public toJson(): any {
        return {
            ...this,
        }
    }
}