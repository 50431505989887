import {InjectionKey, watch} from 'vue';
import {createStore, useStore as useVuexStore, Store as VuexStore} from 'vuex'

import Store from '@/models/store';
import Entity from '@/models/entity';
import useProvider from '@/hooks/provider';
import {deepCopy} from '@/helpers/deepCopy';
import Organization from '@/models/organization';
import {Period} from '@/models/common/period';
import {VATDisplayType} from '@/models/enums/vatdisplay.enum';
import {authState} from '@/states/auth';

export const lineOptions = [10,15,20,25,50,100,200];


export interface GlobalState {
    ready: boolean,
    available: {
        entityIds: number[],
        storeIds: number[]
    },
    selected: {
        entityIds: number[],
        storeIds: number[]
    },
    organisation: Organization | null,
    entities: Entity[],
    stores: Store[],
    period?: Period,
    lines: number,
    vatDisplay: VATDisplayType
}

const initialState: GlobalState = {
    ready: false,
    available: {
        entityIds: [],
        storeIds: []
    },
    selected: {
        entityIds: [],
        storeIds: []
    },
    organisation: null,
    entities: [],
    stores: [],
    lines: 50,
    vatDisplay: VATDisplayType.TVAC
}

export const globalState: VuexStore<GlobalState> = createStore<GlobalState>({
    state: initialState,
    mutations: {
        ready (state, payload: boolean) { state.ready = payload },
        init (state) { state = initialState },
        load (state, payload: Partial<GlobalState>) {
            state.ready = true;
            if (payload.selected) state.selected = deepCopy(payload.selected);
            if (payload.available) state.available = deepCopy(payload.available);
            if (payload.organisation) state.organisation = deepCopy(payload.organisation);
            if (payload.entities) state.entities = deepCopy(payload.entities);
            if (payload.stores) state.stores = deepCopy(payload.stores);
        },
        setEntityIds(state, entityIds: number[]) {
            state.available.entityIds =
                state.entities
                    .map(e => e.id!);
            const t = entityIds.filter(x => state.available.entityIds.includes(x));
            const newEntityIds = t.filter(x => !state.selected.entityIds.includes(x));
            state.selected.entityIds = t;
            state.available.storeIds =
                state.stores
                    .filter(e => state.selected.entityIds.includes(e.entity_id!))
                    .map(e => e.id!);
            state.selected.storeIds = state.stores
                .filter(e => state.selected.storeIds.includes(e.id!) &&
                                    state.selected.entityIds.includes(e.entity_id!) ||
                                    newEntityIds.includes(e.entity_id!))
                .map(e => e.id!);
        },
        setStoreIds(state, storeIds: number[]) {
            state.selected.storeIds = state.stores
                .filter(e => state.available.storeIds.includes(e.id!) &&
                                    storeIds.includes(e.id!))
                .map(e => e.id!);
        },
        setPeriod(state, period: Period){
            state.period = period
        },
        setLines(state, lines: number) {
            state.lines = lines
        },
        setVATDisplay(state, vatDisplay: VATDisplayType) {
            state.vatDisplay = vatDisplay;
        }
    },
    getters: {
        ready: (state)=> state.ready,
        currentOrganisation: (state) => state.organisation,
        availableEntities: (state) => state.entities.filter(e => state.available.entityIds.includes(e.id!)),
        availableStores: (state) => state.stores.filter(e => state.available.storeIds.includes(e.id!)),
        selectedEntities: (state) => state.entities.filter(e => state.selected.entityIds.includes(e.id!)),
        selectedStores:(state) => state.stores.filter(e => state.selected.storeIds.includes(e.id!)),
        period: (state)=> state.period,
        lines: (state) => state.lines,
        vatDisplay: (state) => state.vatDisplay
    },
    actions: {
        async load(store) {
            store.commit('ready', false);
            const provider = useProvider();
            if (authState.state.accessToken == null) {
                store.commit('init');
                return;
            }

            const organisation = await provider.organization.fetchOrganization()

            const entities = await provider.entity.fetchEntities() ?? [];
            const entityIds = entities.map(e => e.id!);

            const stores = authState.state.organization ? await provider.store.fetchStores() : [];

            store.commit('load', {
                available: {
                    entityIds: entities.map(e => e.id!),
                    storeIds: stores.filter(e => entityIds.includes(e.entity_id!)).map(e => e.id!)
                },
                selected: {
                    entityIds: entities.map(e => e.id!),
                    storeIds: stores.filter(e => entityIds.includes(e.entity_id!)).map(e => e.id!)
                },
                organisation,
                entities,
                stores
            })
        },
        async empty (store) {
            const provider = useProvider();
            const organisation = await provider.organization.fetchOrganization()

            store.commit('load', {
                available: { entityIds: [],  storeIds: [] },
                selected: { entityIds: [], storeIds: [] },
                organisation,
                entities: [],
                stores: []
            })
        },
        async refreshEntities(store) {
            const provider = useProvider();
            if ((globalState.state?.organisation?.id ?? 2000) == 2000) store.commit('empty');

            const entities = await provider.entity.fetchEntities() ?? [];
            const entityIds = entities.map(e => e.id!);

            const stores = await provider.store.fetchStores();
            store.commit('load', {
                available: {
                    entityIds: entities.map(e => e.id!),
                    storeIds: stores.filter(e => entityIds.includes(e.entity_id!) && store.state.available.storeIds.includes(e.id!)).map(e => e.id!)
                },
                selected: {
                    entityIds: entities.filter(e => store.state.selected.entityIds.includes(e.id!)).map(e => e.id!),
                    storeIds: stores.filter(e => entityIds.includes(e.entity_id!) && store.state.selected.storeIds.includes(e.id!)).map(e => e.id!)
                },
                entities,
                stores
            })
        },
        async refreshStores(store) {
            const provider = useProvider();
            if ((globalState.state?.organisation?.id ?? 2000) == 2000) store.commit('empty');

            const stores = await provider.store.fetchStores();

            store.commit('load',  {
                    available: {
                        entityIds: store.state.entities.map(e => e.id!),
                        storeIds: stores.filter(e => store.state.selected.entityIds.includes(e.entity_id!) && store.state.available.storeIds.includes(e.id!)).map(e => e.id!)
                    },
                    selected: {
                        entityIds: store.state.entities.filter(e => store.state.selected.entityIds.includes(e.id!)).map(e => e.id!),
                        storeIds: stores.filter(e => store.state.selected.entityIds.includes(e.entity_id!) && store.state.selected.storeIds.includes(e.id!)).map(e => e.id!)
                    },
                entities: store.state.entities,
                stores
            });
        },
        clear(store) { store.commit('load', initialState); },
        setEntityIds(store, entityIds: number[]) {
            store.commit('setEntityIds', entityIds);
        },
        setStoreIds(store, storeIds: number[]) {
            store.commit('setStoreIds', storeIds);
        },
        setPeriod(store, period: Period) {
            store.commit('setPeriod', period)
        },
        setLines(store, lines: number) {
            store.commit('setLines', lines)
        },
        setVATDisplay(store, vatDisplay: VATDisplayType) {
            store.commit('setVATDisplay', vatDisplay)
        }
    },
    plugins: [
        (store) => watch(() => authState.state.globalUser, () => store.dispatch('load'))
    ]
})
export const globalStateKey: InjectionKey<VuexStore<GlobalState>> = Symbol()
export const useGlobalState = (): VuexStore<GlobalState> => useVuexStore(globalStateKey);
export default useGlobalState;

