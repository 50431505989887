import Supplier from "@/models/supplier"
import PagedSuppliers from "@/models/paged/pagedsuppliers";
import BaseProvider from "./baseprovider"
import '../helpers/addParams';
import { PagedParams } from '@/models/paged/pagedparams';
import PagedItems from "@/models/paged/pageditems";
import NameSupplier from '@/models/name/namesuppliermodel';

export default class SupplierProvider extends BaseProvider {

    public async getSupplierNames(entityIds: number[] ): Promise<NameSupplier[]> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("name", [true]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/suppliers${p}`);

        return response.data.map((d: any) => { const t = new NameSupplier(); t.fromJson(d); return t; } );
    }

    public async fetchPagedSuppliers(entityIds: number[], pagedParams?: PagedParams): Promise<PagedSuppliers> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/suppliers${p}`);

        const pagedSuppliers = new PagedSuppliers(response.data);

        return pagedSuppliers;
    }

    public async getSupplierFromUid(supplierUid: string): Promise<Supplier> {
        const response = await (await this.getApiV2()).get(`/suppliers/${supplierUid}`);

        const suppliers = this.getSupplier(response.data);

        return suppliers;
    }

    /**
     * Get the given supplier's items
     */
    public async fetchSupplierItems(supplierUid: string, entityIds: number[], pagedParams?: PagedParams): Promise<PagedItems> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'date',
            sortOrder: 'desc'
        });

        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/Suppliers/${supplierUid}/Items${p}`);

        return new PagedItems(response.data);
    }

    public async saveSupplier(supplier: Supplier): Promise<Supplier> {
        const response = await (await this.getApiV2()).post(`/suppliers/`, supplier);

        const suppliers = this.getSupplier(response.data);

        return suppliers;
    }

    private getSupplier(data: any): Supplier {
        const supplier = new Supplier(data);

        supplier.language = supplier.language?.toUpperCase();

        return supplier;
    }
}
