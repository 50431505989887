import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarCollapsableItem = _resolveComponent("SidebarCollapsableItem")!
  const _component_PanelMenu = _resolveComponent("PanelMenu")!

  return _withDirectives((_openBlock(), _createBlock(_component_PanelMenu, {
    multiple: "",
    model: _ctx.items
  }, {
    item: _withCtx(({ item }) => [
      _createVNode(_component_SidebarCollapsableItem, { item: item }, null, 8, ["item"])
    ]),
    _: 1
  }, 8, ["model"])), [
    [_vShow, _ctx.item.visible]
  ])
}