import filters from '@/helpers/filters'
import useLocalization from '@/hooks/localization'
import Entity from '@/models/entity'
import { globalState } from '@/states/global'
import { FontAwesomeFamilyType } from '@/types/FontAwesomeFamilyType'
import { RouteRecordName } from 'vue-router'

export type MainMenuItem = {
  icon: string
  link: string
  name: string
}

export function getMainMenu(isSystem: boolean) {
  const r: MainMenuItem[] = []

  if (isSystem) {
    r.push({ icon: 'cog', link: 'global-users', name: 'Settings' })
  } else {
    r.push({ icon: 'fas fa-bag-shopping', link: 'items', name: 'items' })
    r.push({
      icon: 'user-tie',
      link: 'customers',
      name: filters.cflt('customers'),
    })
    r.push({
      icon: 'person-dolly',
      link: 'suppliers',
      name: filters.cflt('suppliers'),
    })
    r.push({
      icon: 'file-invoice-dollar',
      link: 'sale-quotes',
      name: filters.cflt('sales'),
    })
    r.push({
      icon: 'clipboard-list',
      link: 'purchase-quotes',
      name: filters.cflt('purchases'),
    })
    r.push({
      icon: 'right-left',
      link: 'storetransfer-direct',
      name: filters.cflt('storetransfer_movements'),
    })
    r.push({
      icon: 'boxes',
      link: 'storetransfer-stock-correction',
      name: filters.cflt('storetransfer_stock'),
    })

    if (
      globalState.getters.selectedEntities.filter(
        (e: Entity) =>
          (e.chift_consumer_id ?? '').length > 0 &&
          e.accounting_software_id == 3
      )?.length > 0
    )
      r.push({
        icon: 'calculator',
        link: 'accounting/sales',
        name: filters.cflt('accounting'),
      })

    r.push({ icon: 'cog', link: 'pos', name: 'Settings' })
  }

  /*
  r.push({ icon: 'truck', link: 'suppliers', name: 'Suppliers' });
  r.push({ icon: 'file-upload', link: 'sales', name: 'Sales' });
  r.push({ icon: 'file-download', link: 'expenses', name: 'Expenses' });
  r.push({ icon: 'address-book', link: 'users', name: 'Users' });
  r.push({ icon: 'chart-pie', link: 'reporting', name: 'Reporting' });
  r.push({ icon: 'bell', link: 'events', name: 'Events' });
  r.push({ icon: 'fas fa-certificate', link: 'changehistory', name: 'change_history' });
  */

  return r
}

export class SubMenuItem {
  icon?: string
  iconClass?: string
  iconPath?: string
  iconFamily?: FontAwesomeFamilyType = 'solid'
  iconSharp?: boolean = false
  iconHtml?: string
  link?: string
  color?: string
  name?: string
  visible: boolean = true
  group?: string
  items?: SubMenuItem[]
  constructor(init?: Partial<SubMenuItem>) {
    Object.assign(this, init)
  }
}

export type SubMenu = {
  title?: string
  subItems: (SubMenuItem | string)[]
}

const routesSettingsIsSystem = [
  'settings',
  'global-users',
  'global-user-edition',
]

const routesAccountIsSystem = [
  'credentials',
  'activelicenses',
  'activecontracts',
  'blockedlicenses',
  'demolicenses',
  'inactiveslicenses',
  'unbindlicenses',
  'emailhistory',
  'smshistory',
  'organisationlogs',
  'entitiesprodready',
  'entitiesprodunready',
  'licenseentity-edition',
  'licensepos-edition',
]

const routesDashboard = [
  'dashboard',
  'dashboardca',
  'dashboardreportz',
  'dashboarditemlevels',
  'dashboardcaglobalusers',
  'dashboardpointglobalusers',
  'dashboardsalesdetails',
  'dashboardlisting',
  'dashboarddatawarehouse',
]

const routesItems = [
  'items',
  'item-edition',
  'item-levels',
  'item-level-edition',
  'item-categories',
  'item-category-edition',
  'item-history',
  'item-history-list',
  'item-history-view',
  'item-ranks',
  'item-rank-edition',
  'propositions',
  'proposition-edition',
  'stocks',
  'stock-item-edition',
]

const routesCustomers = [
  'customers',
  'customer-edition',
  'customer-history',
  'customer-category-edition',
  'customer-categories',
  'customer-addresses',
  'customer-address-edition',
]

const routesSuppliers = [
  'suppliers',
  'supplier-edition',
  'supplier-history',
  'supplier-account',
]

const routesSales = [
  'sale-quotes',
  'sale-quotes-view',
  'sale-orders',
  'sale-orders-view',
  'sale-deliveries',
  'sale-deliveries-view',
  'sale-invoices',
  'sale-invoices-view',
  'sale-edition',
  'sale-creation',
  'sale-creation-from',
]

const routesPurchases = [
  'purchase-quotes',
  'purchase-quotes-view',
  'purchase-orders',
  'purchase-orders-view',
  'purchase-deliveries',
  'purchase-deliveries-view',
  'purchase-invoices',
  'purchase-invoices-view',
  'purchase-edition',
  'purchase-creation',
]

const routesStoreTransfersMovements = [
  'storetransfer-direct',
  'storetransfer-direct-edition',
  'storetransfer-direct-creation',
  'storetransfer-direct-view',
  'storetransfer-flow-validating',
  'storetransfer-flow-validating-view',
  'storetransfer-flow-preparing',
  'storetransfer-flow-preparing-view',
  'storetransfer-flow-refused',
  'storetransfer-flow-refused-view',
  'storetransfer-flow-expedited',
  'storetransfer-flow-expedited-view',
  'storetransfer-flow-delivered',
  'storetransfer-flow-delivered-view',
  'storetransfer-flow-edition',
  'storetransfer-flow-edition-view',
  'storetransfer-flow-creation',
  'storetransfer-flow-creation-view',
]

const routesStoreTransfersStock = [
  'storetransfer-stock-correction',
  'storetransfer-stock-global-reset',
  'storetransfer-stock-global-transcription',
]

const routesAccounting = ['accounting-sales', 'accounting-purchases']

const routesSettings = [
  'settings',
  'my-organization',
  'global-users',
  'global-user-edition',
  'glory-user-types',
  'glory-user-type-edition',
  'stores',
  'store-edition',
  'store-outputs',
  'entities',
  'entity-edition',
  'pos',
  'pos-edition',
  'signalr',
  'api',
  'changehistory',
  'webhooks',
  'webhook-edition',
  'printers',
  'printer-edition',
  'outputs',
  'price-lists',
  'price-list-edition',
  'mail-templates',
  'mail-template-edition',
  'tools',
  'item-printer-links',
  'item-proposition-links',
  'item-rank-links',
  'pos-matrix-template-link',
  'lightspeed',
  'matrix-templates',
  'matrix-template-edition',
]

const routesAccount = ['credentials']

const routesClickAndCollect = [
  'click-and-collect',
  'click-and-collect:storefronts:list',
  'click-and-collect:storefronts:show',
  'click-and-collect:branding:list',
  'click-and-collect:branding:update',
  'click-and-collect:branding:create',
]

export function getSubMenu(
  isSystem: boolean,
  isAdmin: boolean,
  routeName: RouteRecordName | null | undefined,
  listings?: any[],
  datawarehouses?: any[]
): SubMenu | undefined {
  const localization = useLocalization()

  const r = routeName?.toString()

  if (!r) {
    return
  }

  //------------------------------------------ SUPER ORGANISATION MENU ------------------------------------------
  if (isSystem) {
    if (routesSettingsIsSystem.includes(r))
      return {
        title: filters.cflt('settings'),
        subItems: [
          new SubMenuItem({
            icon: 'users',
            link: 'global-users',
            color: 'greenfield',
            name: 'Users',
          }),
        ],
      }
    else if (routesAccountIsSystem.includes(r))
      return {
        title: filters.cflt('Account.Account'),
        subItems: [
          new SubMenuItem({
            icon: 'user',
            link: 'account/credentials',
            color: 'redcarmin',
            name: filters.cflt('Account.Credentials'),
          }),
          filters.cflt('Licenses.Licenses'),
          new SubMenuItem({
            icon: 'list-ul',
            link: 'account/activelicenses',
            color: 'bluesky',
            name: filters.cflt('Licenses.Active'),
            visible: isSystem,
          }),
          new SubMenuItem({
            link: 'account/activecontracts',
            color: 'bluesky',
            name: filters.cflt('Licenses.Contracts'),
            visible: isSystem,
            icon: 'file-contract',
            iconClass: 'fal',
          }),
          new SubMenuItem({
            link: 'account/blockedlicenses',
            color: 'bluesky',
            name: filters.cflt('Licenses.Blocked'),
            visible: isSystem,
            iconHtml: `<span class='fa-stack'>
                                    <i class='fas fa-list-ul'></i>
                                    <i class='fas fa-ban fa-stack-1x color-redcarmin'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
          }),
          new SubMenuItem({
            link: 'account/inactiveslicenses',
            color: 'bluesky',
            name: filters.cflt('Licenses.Inactives'),
            visible: isSystem,
            iconHtml: `<span class='fa-stack'>
                                    <i class='fas fa-list-ul'></i>
                                    <i class='fas fa-handshake-slash fa-stack-1x color-orange'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
          }),

          new SubMenuItem({
            link: 'account/demolicenses',
            color: 'bluesky',
            name: filters.cflt('Licenses.Demo'),
            visible: isSystem,
            iconHtml: `<span class='fa-stack'>
                                    <i class='fas fa-list-ul'></i>
                                    <i class='fas fa-chalkboard-teacher fa-stack-1x color-purple'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
          }),

          new SubMenuItem({
            link: 'account/unbindlicenses',
            color: 'bluesky',
            name: filters.cflt('Licenses.Unbind'),
            visible: isSystem,
            iconHtml: `<span class='fa-stack'>
                                    <i class='fas fa-list-ul'></i>
                                    <i class='fas fa-link fa-stack-1x color-pumpkin'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
          }),
          filters.cflt('Account.History'),
          new SubMenuItem({
            link: 'account/emailhistory',
            color: 'bluesky',
            name: filters.cflt('History.Email'),
            visible: isSystem,
            iconHtml: `<span class='fa-stack'>
                                    <i class='fas fa-envelope'></i>
                                    <i class='fas fa-clock-rotate-left fa-stack-1x color-coal'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
          }),
          new SubMenuItem({
            link: 'account/smshistory',
            color: 'bluesky',
            name: filters.cflt('History.SMS'),
            visible: isSystem,
            iconHtml: `<span class='fa-stack'>
                                    <i class='fal fa-mobile'></i>
                                    <i class='fas fa-clock-rotate-left fa-stack-1x color-coal'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
          }),
          filters.cflt('Account.Other'),
          new SubMenuItem({
            link: 'account/organisationlogs',
            color: 'purple',
            name: filters.cflt('organisation_logs'),
            visible: isSystem,
            icon: 'book',
          }),
          /* new SubMenuItem({
              icon: 'building',
              link: 'account/licensescustomers',
              color: 'blueroyal',
              name: filters.cflt('Licenses.InvoiceSheets')
          }), */
          new SubMenuItem({
            link: 'account/entitiesprodready',
            name: filters.cflt('Licenses.EntitiesProdReady'),
            iconHtml: `<span class='fa-stack'>
                                    <i class='fas fa-building color-blueroyal'></i>
                                    <i class='fas fa-flag-checkered fa-stack-1x color-greenfield'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
          }),
          new SubMenuItem({
            link: 'account/entitiesprodunready',
            name: filters.cflt('Licenses.EntitiesProdUnready'),
            iconHtml: `<span class='fa-stack'>
                                    <i class='fas fa-building color-blueroyal'></i>
                                    <i class='fas fa-hourglass-half fa-stack-1x color-orange'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
          }),
        ],
      }
    else return { title: '', subItems: [] }
  }
  //----------------------------------------------- STANDARD MENU -----------------------------------------------
  else if (routesDashboard.includes(r)) {
    const groups = [
      ...new Set(
        [
          ...(listings?.map(
            (x) => localization.localize(x.globalCategory) ?? undefined
          ) || []),
          ...(datawarehouses?.map(
            (x) => localization.localize(x.globalCategory) ?? undefined
          ) || []),
        ].filter((value, index, self) => self.indexOf(value) === index)
      ),
    ].sort()

    const dashboardGroupMenuItems: SubMenuItem[] =
      groups?.flatMap((group) => [
        ...(listings
          ?.filter((x) => localization.localize(x.globalCategory) === group)
          .map(
            (x) =>
              new SubMenuItem({
                group: group,
                iconPath: x.listing?.icon_azure_path,
                link: 'dashboard/listing/' + x.listing?.id,
                name: localization.localize(x.listing) ?? undefined,
              })
          ) || []),
        ...(datawarehouses
          ?.filter((x) => localization.localize(x.globalCategory) === group)
          .map(
            (x) =>
              new SubMenuItem({
                group: group,
                icon: 'sitemap', // datawarehouse.datawarehouse?.icon_azure_path,
                link: 'dashboard/datawarehouse/' + x.datawarehouse?.id,
                name: localization.localize(x.datawarehouse) ?? undefined,
              })
          ) || []),
      ]) ?? []

    // Dashboards
    return {
      title: 'Dashboard',
      subItems: [
        new SubMenuItem({
          icon: 'chart-line',
          link: 'dashboard/ca',
          color: 'redcarmin',
          name: filters.cflt('turnover'),
        }),
        new SubMenuItem({
          icon: 'file-invoice',
          link: 'dashboard/reportz',
          color: 'pumpkin',
          name: filters.cflt('report_z'),
        }),
        new SubMenuItem({
          icon: 'sitemap',
          link: 'dashboard/itemlevels',
          color: 'gold',
          name: filters.cflt('by_item_levels'),
        }),
        new SubMenuItem({
          icon: 'users',
          link: 'dashboard/caglobalusers',
          color: 'bluesky',
          name: filters.cflt('ca_global_users'),
        }),
        new SubMenuItem({
          icon: 'user-clock',
          link: 'dashboard/pointglobalusers',
          color: 'blueroyal',
          name: filters.cflt('point_global_users'),
        }),
        new SubMenuItem({
          icon: 'shopping-bag',
          link: 'dashboard/salesdetails',
          color: 'greenforest',
          name: filters.cflt('sales_details'),
        }),
        ...dashboardGroupMenuItems,
      ],
    }
  } else if (routesItems.includes(r))
    // Items
    return {
      title: filters.cflt('items'),
      subItems: [
        new SubMenuItem({
          icon: 'box-open',
          link: 'items',
          color: 'redcarmin',
          name: filters.cflt('items'),
        }),
        new SubMenuItem({
          icon: 'sitemap',
          link: 'item-levels',
          color: 'blueroyal',
          name: filters.cflt('item_levels'),
        }),
        new SubMenuItem({
          icon: 'sitemap',
          link: 'item-categories',
          color: 'purple',
          name: filters.cflt('item_categories'),
        }),
        new SubMenuItem({
          icon: 'link',
          link: 'propositions',
          color: 'greenfield',
          name: filters.cflt('propositions'),
        }),
        // { icon: 'table-cells', link: 'stores?from=items', color: 'blueroyal', name: filters.cflt('matrices') },
        new SubMenuItem({
          icon: 'cubes',
          link: 'stocks',
          color: 'gold',
          name: filters.cflt('stocks'),
        }),
        new SubMenuItem({
          icon: 'history',
          link: 'item-history',
          color: 'bluesky',
          name: filters.cflt('item_history'),
        }),
        new SubMenuItem({
          icon: 'sort-numeric-down',
          link: 'item-ranks',
          color: 'greenforest',
          name: filters.cflt('ranks'),
        }),
        // { icon: 'crop', link: 'items/resize', color: 'greenfield', name: 'Resize', },
        // new SubMenuItem({ icon: 'th-large', link: 'clients', color: 'pumpkin', name: 'Matrices', }),
        // new SubMenuItem({ icon: 'sitemap', link: 'suppliers', color: 'gold', name: 'Hiérarchies', }),
        // new SubMenuItem({ icon: 'list', link: 'sales', color: 'greenfield', name: 'Catégories', }),
        // new SubMenuItem({ icon: 'link', link: 'expenses', color: 'greenforest', name: 'Propositions', }),
        // new SubMenuItem({ icon: 'tags', link: 'users', color: 'bluesky', name: 'Listes de Prix', }),
        // new SubMenuItem({ icon: 'boxes', link: 'reporting', color: 'blueroyal', name: 'Inventaires', }),
      ],
    }
  else if (routesCustomers.includes(r))
    // Customers
    return {
      title: filters.cflt('customers'),
      subItems: [
        new SubMenuItem({
          icon: 'user-tie',
          link: 'customers',
          color: 'redcarmin',
          name: filters.cflt('customers'),
        }),
        new SubMenuItem({
          icon: 'sitemap',
          link: 'customer-categories',
          color: 'gold',
          name: filters.cflt('customer_categories'),
        }),
        new SubMenuItem({
          icon: 'building',
          link: 'customer-addresses',
          color: 'greenfield',
          name: filters.cflt('addresses'),
        }),
      ],
    }
  else if (routesSuppliers.includes(r))
    // Suppliers
    return {
      title: filters.cflt('suppliers'),
      subItems: [
        new SubMenuItem({
          icon: 'person-dolly',
          link: 'suppliers',
          color: 'redcarmin',
          name: filters.cflt('suppliers'),
        }),
      ],
    }
  else if (routesSales.includes(r))
    // Sales
    return {
      title: filters.cflt('sales'),
      subItems: [
        new SubMenuItem({
          icon: 'fa fa-circle-question',
          link: 'sale-quotes',
          color: 'gold',
          name: filters.cflt('sale_quotes'),
        }),
        new SubMenuItem({
          icon: 'fa fa-clock',
          link: 'sale-orders',
          color: 'bluesky',
          name: filters.cflt('sale_orders'),
        }),
        new SubMenuItem({
          icon: 'fa fa-file-circle-check',
          link: 'sale-deliveries',
          color: 'greenforest',
          name: filters.cflt('sale_deliveries'),
        }),
        new SubMenuItem({
          icon: 'fa fa-file-invoice-dollar',
          link: 'sale-invoices',
          color: 'redcarmin',
          name: filters.cflt('sale_invoices'),
        }),
      ],
    }
  else if (routesPurchases.includes(r))
    // Purchases
    return {
      title: filters.cflt('purchases'),
      subItems: [
        new SubMenuItem({
          icon: 'fa fa-clipboard-check',
          link: 'purchase-quotes',
          color: 'redcarmin',
          name: filters.cflt('purchase_quotes'),
        }),
        new SubMenuItem({
          icon: 'fa fa-bullhorn',
          link: 'purchase-orders',
          color: 'gold',
          name: filters.cflt('purchase_orders'),
        }),
        new SubMenuItem({
          icon: 'fa fa-truck',
          link: 'purchase-deliveries',
          color: 'greenfield',
          name: filters.cflt('purchase_deliveries'),
        }),
        new SubMenuItem({
          icon: 'fa fa-file-alt',
          link: 'purchase-invoices',
          color: 'blueroyal',
          name: filters.cflt('purchase_invoices'),
        }),
      ],
    }
  else if (routesStoreTransfersMovements.includes(r))
    // Store Transfers Movements
    return {
      title: filters.cflt('storetransfer_movements'),
      subItems: [
        new SubMenuItem({
          icon: 'fa fa-long-arrow-right',
          link: 'storetransfer-direct',
          color: 'bluesky',
          name: filters.cflt('storetransfer_direct'),
        }),
        new SubMenuItem({
          group: filters.cflt('storetransfer_flow'),
          icon: 'fal fa-spinner',
          link: 'storetransfer-flow-validating',
          color: 'greenfield',
          name: filters.cflt('storetransfer_flow_validating'),
        }),
        new SubMenuItem({
          group: filters.cflt('storetransfer_flow'),
          icon: 'fal fa-thumbs-up',
          link: 'storetransfer-flow-preparing',
          color: 'blueroyal',
          name: filters.cflt('storetransfer_flow_preparing'),
        }),
        new SubMenuItem({
          group: filters.cflt('storetransfer_flow'),
          icon: 'fas fa-truck',
          link: 'storetransfer-flow-expedited',
          color: 'purple',
          name: filters.cflt('storetransfer_flow_expedited'),
        }),
        new SubMenuItem({
          group: filters.cflt('storetransfer_flow'),
          icon: 'fa fa-box-circle-check',
          link: 'storetransfer-flow-delivered',
          color: 'greenforest',
          name: filters.cflt('storetransfer_flow_delivered'),
        }),
        new SubMenuItem({
          group: filters.cflt('storetransfer_flow'),
          icon: 'fas fa-ban',
          link: 'storetransfer-flow-refused',
          color: 'redcarmin',
          name: filters.cflt('storetransfer_flow_refused'),
        }),
      ],
    }
  else if (routesStoreTransfersStock.includes(r))
    // Store Transfers Stock
    return {
      subItems: [
        new SubMenuItem({
          icon: 'fa fa-edit',
          link: 'storetransfer-stock-correction',
          color: 'gold',
          name: filters.cflt('storetransfer_stock_correction'),
        }),
        new SubMenuItem({
          group: filters.cflt('storetransfer_stock_global'),
          icon: 'fa fa-eraser',
          link: 'storetransfer-stock-global-reset',
          color: 'redcarmin',
          name: filters.cflt('storetransfer_stock_global_reset'),
        }),
        new SubMenuItem({
          group: filters.cflt('storetransfer_stock_global'),
          icon: 'fa fa-pen-square',
          link: 'storetransfer-stock-global-transcription',
          color: 'purple',
          name: filters.cflt('storetransfer_stock_global_transcription'),
        }),
      ],
    }
  else if (routesAccounting.includes(r))
    // Accounting
    return {
      title: filters.cflt('accounting'),
      subItems: [
        new SubMenuItem({
          link: 'accounting/sales',
          color: 'blueroyal',
          name: filters.cflt('accounting_sales'),
          iconHtml: `<span class='fa-stack'>
                                    <i class='fas fa-file-invoice-dollar'></i>
                                    <i class='fas fa-calculator fa-stack-1x color-redcarmin'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
        }),
        new SubMenuItem({
          link: 'accounting/purchases',
          color: 'greenfield',
          name: filters.cflt('accounting_purchases'),
          iconHtml: `<span class='fa-stack'>
                                    <i class='fas fa-clipboard-list'></i>
                                    <i class='fas fa-calculator fa-stack-1x color-redcarmin'
                                       style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                               </span>`,
        }),
      ],
    }
  else if (routesSettings.includes(r) || routesClickAndCollect.includes(r))
    // Settings
    return {
      title: filters.cflt('settings'),
      subItems: [
        new SubMenuItem({
          icon: 'briefcase',
          link: 'my-organization',
          color: 'redcarmin',
          name: filters.cflt('my-organization'),
        }),
        new SubMenuItem({
          icon: 'tablet-alt',
          link: 'pos',
          color: 'pumpkin',
          name: 'POS',
        }),
        new SubMenuItem({
          icon: 'users',
          link: 'global-users',
          color: 'greenfield',
          name: 'Users',
        }),
        new SubMenuItem({
          icon: 'coins',
          link: 'glory-user-types',
          color: 'greenforest',
          name: 'Glory user types',
        }),
        new SubMenuItem({
          icon: 'store',
          link: 'stores',
          color: 'bluesky',
          name: 'Stores',
        }),
        new SubMenuItem({
          icon: 'table-cells',
          link: 'matrix-templates',
          color: 'redcarmin',
          name: 'Matrix templates',
        }),
        new SubMenuItem({
          icon: 'building',
          link: 'entities',
          color: 'blueroyal',
          name: 'Entities',
        }),
        new SubMenuItem({
          icon: 'list-ul',
          link: 'price-lists',
          color: 'purple',
          name: filters.cflt('price-lists'),
        }),
        new SubMenuItem({
          icon: 'envelope',
          link: 'mail-templates',
          color: 'greenfield',
          name: filters.cflt('mail_templates'),
        }),
        new SubMenuItem({
          icon: 'print',
          link: 'printers',
          color: 'gold',
          name: filters.cflt('printers'),
        }),
        new SubMenuItem({
          icon: 'print',
          link: 'stores?highlight=printers',
          color: 'greenforest',
          name: filters.cflt('outputs'),
        }),
        new SubMenuItem({
          icon: 'cloud-upload',
          link: 'signalr',
          color: 'blueroyal',
          name: filters.cflt('push_download'),
        }),
        new SubMenuItem({
          group: 'click-and-collect',
          icon: 'shop',
          iconFamily: 'duotone',
          color: 'blueroyal',
          name: filters.cflt('Layout.SidebarClickAndCollect'),
          visible: false,
          items: [
            new SubMenuItem({
              icon: 'shop',
              iconFamily: 'duotone',
              link: 'click-and-collect/storefronts',
              color: 'gold',
              name: filters.cflt('Layout.SidebarClickAndCollectStorefronts'),
            }),
            new SubMenuItem({
              icon: 'palette',
              iconFamily: 'duotone',
              link: 'click-and-collect/branding',
              color: 'bluesky',
              name: filters.cflt('Layout.SidebarClickAndCollectBranding'),
            }),
          ],
        }),
        new SubMenuItem({
          icon: 'code-branch',
          link: 'api',
          color: 'greenfield',
          name: 'API',
        }),
        new SubMenuItem({
          icon: 'pencil',
          link: 'webhooks',
          color: 'steel',
          name: 'Webhooks',
        }),
        new SubMenuItem({
          icon: 'code-branch',
          link: 'tools',
          color: 'redcarmin',
          name: 'Tools',
        }),
        new SubMenuItem({
          icon: 'certificate',
          link: 'changehistory',
          color: 'yellow',
          name: 'Change history',
        }),
        /* {
          group: filters.cflt('matrices'),
          link: 'matrices/globals', color: 'purple', name: filters.cflt('matrices_globals'),
          iconHtml: `<span class='fa-stack'>
                        <i class='fas fa-table-cells'></i>
                        <i class='fas fa-store fa-stack-1x color-bluesky'
                           style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                     </span>`,
        },
        {
          group: filters.cflt('matrices'),
          link: 'matrices/centralregisters', color: 'purple', name: filters.cflt('matrices_centralregisters')
          iconHtml: `
              <span class='fa-stack'>
                  <i class='fas fa-table-cells'></i>
                  <i class='fas fa-cash-register fa-stack-1x color-redcarmin'
                     style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
              </span>
          `,
        },
        {
          group: filters.cflt('matrices'),
          link: 'matrices/smartkiosk', color: 'blueroyal', name: filters.cflt('matrices_smartkiosk')
          iconHtml: `<span class='fa-stack'>
                        <i class='fas fa-table-cells'></i>
                        <i class='fas fa-tablet-button fa-stack-1x color-greenfield'
                           style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                     </span>`,
        },
        {
          group: filters.cflt('matrices'),
          link: 'matrices/clickandcollect', color: 'purple', name: filters.cflt('matrices_clickandcollect')
          iconHtml: `<span class='fa-stack'>
                        <i class='fas fa-table-cells'></i>
                        <i class='fas fa-arrow-pointer fa-stack-1x color-lightcoral'
                           style='font-size: 0.8rem; background: transparent; top:0.6em; left: 0.5em' />
                     </span>`,
        } */
      ],
    }
  else if (routesAccount.includes(r))
    return {
      title: filters.cflt('account'),
      subItems: [
        new SubMenuItem({
          icon: 'user',
          link: 'account/credentials',
          color: 'redcarmin',
          name: filters.cflt('credentials'),
        }),
      ],
    }
  else return { title: '', subItems: [] }
}
