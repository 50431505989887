import ItemStore from '@/models/itemstore';

export default class PropositionTo {
    public uid?: string;
    public order_number?: number;
    public price?: number | null = null;
    public quantity?: number | null = null;
    public to_item_uid?: string;

    public stores?: ItemStore[];

    constructor(init?: Partial<PropositionTo>) {
        Object.assign(this, init);
    }
}
