import BaseProvider from '@/providers/baseprovider';
import { PagedParams } from '@/models/paged/pagedparams';
import PagedStoreTransfers from '@/models/paged/pagedstoretransfers';
import moment from 'moment';

export default class StoreTransferProvider extends BaseProvider {
    public async fetchPagedStoreTransfers(
        pagedParams?: PagedParams,
        params?: {
            entityIds: number[],
            storeIds?: number[],
            storeUids?: string[],
            fromStoreIds?: number[],
            fromStoreUids?: string[],
            toStoreIds?: number[],
            toStoreUids?: string[],
            flowTypeIds?: number[],
            flowStatusIds?: number[],
            flowStatusValidation?: (number | null)[],
            fromInventory?: boolean,
            toInventory?: boolean,
            fromDate?: Date,
            toDate?: Date
        }): Promise<PagedStoreTransfers> {

        let p: String = '';
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'date',
            sortOrder: 'desc'
        });

        p = p.addParams("entityIds", params?.entityIds, true);
        p = p.addParams("storeIds", params?.storeIds, true);
        p = p.addParams("storeUids", params?.storeUids, true);
        p = p.addParams("fromStoreIds", params?.fromStoreIds, true);
        p = p.addParams("fromStoreUids", params?.fromStoreUids, true);
        p = p.addParams("toStoreIds", params?.toStoreIds, true);
        p = p.addParams("toStoreUids", params?.toStoreUids, true);
        p = p.addParams("flowTypeIds", params?.flowTypeIds, true);
        p = p.addParams("flowStatusIds", params?.flowStatusIds, true);
        p = p.addParams("flowStatusValidation", params?.flowStatusValidation, true);
        p = p.addParams("fromInventory", [params?.fromInventory]);
        p = p.addParams("toInventory", [params?.toInventory]);
        p = p !== '' ? `?${p}` : '';

        if (params?.fromDate && params?.toDate)
            p = `/${moment(params.fromDate).format('yyyy-MM-DD HH:mm:ss')}/${moment(params.toDate).format('yyyy-MM-DD HH:mm:ss')}${p}`;

        const response = await (await this.getApiV2()).get(`storetransfers${p}`);

        return new PagedStoreTransfers(response.data);
    }

    public async getStoreTransferJSON(storeTransferHeaderUid: string): Promise<any> {
        const response = await (await this.getApiV2()).get(`/storetransfers/${storeTransferHeaderUid}`);

        return response.data;
    }

    public async preview(uid: string): Promise<any> {
        const response = await (await this.getApiV2()).get(`/storetransfers/${uid}/preview`);

        return response.data;
    }
}