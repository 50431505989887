
export default class NumberHelper {
    public static round(n: number, precision: number): number {
        return +(Math.round(Number(`${n}e+${precision}`)) + `e-${precision}`);
    }

    public static removeVatRateToAmount(amount: number, vatRate: number): number {
        return amount / (1 + vatRate);
    }

    public static applyVatRateToAmount(amount: number, vatRate: number): number {
        return amount * (1 + vatRate);
    }

    public static applyDiscountRateToAmount(amount: number, discountRate: number): number {
        return amount * (1 - discountRate);
    }
}
