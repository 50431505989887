import BaseProvider from '@/providers/baseprovider';
import { Vies } from '@/models/vies';
import { resolve } from 'chart.js/helpers';

export default class ViesProvider extends BaseProvider {
    public async getViesFromVAT(vat: string): Promise<Vies> {
        if ((vat?.length ?? 0) < 0) resolve({ valid: false });
        const response = await (await this.getApiV2()).get(`/external/vies?vat=${vat}`);
        return this.getVies(response.data);
    }

    private getVies(data: any): Vies {
        return new Vies(data);
    }
}
