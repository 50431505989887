export default class MailTemplate {
  [key: string]: any
  public id?: number
  public uid?: string
  public entity_id?: number
  public name?: string
  public html_content?: string
  public active?: boolean = true

  constructor(init?: Partial<MailTemplate>) {
    Object.assign(this, init)
  }
}
