<template>
  <PanelMenu multiple :model="items" v-show="item.visible">
    <template #item="{ item }">
      <SidebarCollapsableItem :item='item'/>
    </template>
  </PanelMenu>
</template>
<script lang="ts">
import SidebarCollapsableItem from '@/components/Layout/Sidebar/SidebarCollapsableItem/SidebarCollapsableItem.vue'
import RouteHelper from '@/helpers/routehelper'
import { SubMenuItem } from '@/menu/menu'
import { MenuItem } from 'primevue/menuitem'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  components: {
    SidebarCollapsableItem
  },
  props: {
    item: SubMenuItem
  },
  setup(props) {
    const getLink = (path: string) => RouteHelper.getLink(path)
    const subMenuItemToMenuItem = (item: SubMenuItem): MenuItem => ({
      label: item.name,
      route: item?.link,
      icon: item?.icon,
      iconPath: item?.iconPath,
      iconColor: item.color,
      iconFamily: item.iconFamily,
      iconSharp: item.iconSharp,
      items: remapSubMenuItems(item?.items ?? [])
    })
    const remapSubMenuItems = (items: SubMenuItem[]): MenuItem[] => items.map(subMenuItemToMenuItem)
    const items = ref<MenuItem[]>([])  // 'itemMenu' is the new variable here

    watch(() => props.item, (newVal) => {
      if (!newVal) {
        return
      }

      items.value = remapSubMenuItems([ newVal ])
    }, { immediate: true })

    return {
      getLink,
      items
    }
  }
})
</script>
<style lang='scss'>
.p-panelmenu {
  .p-panelmenu-panel {
    .p-panelmenu-header {
      .p-panelmenu-header-content {
        a, li {
          padding: 0 !important;
        }
      }
    }

    .p-toggleable-content {
      .p-panelmenu-content {
        border: 0 !important;

        ul.p-submenu-list {
          padding: 0 0 0 .5rem;

          div.p-menuitem-content {
            a, li {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>
