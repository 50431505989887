import StoreTransferProvider from './storetransferprovider';
import { StoreTransferFlowRepository } from '@/models/repository/storetransferflowrepository';

export default class StoreTransferFlowProvider extends StoreTransferProvider {
    
    public async getStoreTransferRepository(storeTransferHeaderUid: string): Promise<StoreTransferFlowRepository> {
        const data = await this.getStoreTransferJSON(storeTransferHeaderUid);

        const repository = new StoreTransferFlowRepository();
        repository.fromJson(data);

        return repository;
    }

    public async save(repository: StoreTransferFlowRepository): Promise<any> {
        const json = repository.toJson();

        const response = await (await this.getApiV2()).post(`/storetransfers`, json);
        
        return response.data;
    }
}