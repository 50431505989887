import BaseProvider from "./baseprovider"
import CAGlobalUsersModel from '@/models/dashboard/caglobalusersmodel';
import CAReportZModel from '@/models/dashboard/careportzmodel';
import CASummaryPaymentsModel from '@/models/dashboard/casummarypaymentsmodel';
import CASummarySalesModel from '@/models/dashboard/casummarysalesmodel';
import GuestCountModel from '@/models/dashboard/guestcountmodel';
import PointGlobalUsersModel from '@/models/dashboard/pointglobalusersmodel';
import SalesDetailsModel from '@/models/dashboard/salesdetailsmodel';
import CurrentModel from '@/models/dashboard/currentmodel';

export default class DashProvider extends BaseProvider {
    public async getCAGlobalUsers(storeIds: number[], fromDate: string, toDate: string): Promise<CAGlobalUsersModel> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/dash/CAGlobalUsers/${fromDate}/${toDate}${p}`),
            d => new CAGlobalUsersModel(d)
        );
    }

    public async getCAReportZ(storeIds: number[], fromDate: string, toDate: string): Promise<CAReportZModel> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/dash/CAReportZ/${fromDate}/${toDate}${p}`),
            d => new CAReportZModel(d)
        );
    }

    public async getCASummaryPayments(storeIds: number[], fromDate: string, toDate: string): Promise<CASummaryPaymentsModel> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/dash/CASummaryPayments/${fromDate}/${toDate}${p}`),
            d => new CASummaryPaymentsModel(d)
        );
    }

    public async getCASummarySales(storeIds: number[], fromDate: string, toDate: string): Promise<CASummarySalesModel> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/dash/CASummarySales/${fromDate}/${toDate}${p}`),
            d => new CASummarySalesModel(d)
        );
    }

    public async getCurrent(storeIds: number[], fromDate: string, toDate: string): Promise<CurrentModel> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/dash/Current/${fromDate}/${toDate}${p}`),
            d => new CurrentModel(d)
        );
    }

    public async getGuestCount(storeIds: number[], fromDate: string, toDate: string): Promise<GuestCountModel> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/dash/GuestCount/${fromDate}/${toDate}${p}`),
            d => new GuestCountModel(d)
        );
    }

    public async getPointGlobalUsers(storeIds: number[], fromDate: string, toDate: string): Promise<any> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/dash/PointGlobalUsers/${fromDate}/${toDate}${p}`)
        );
    }

    public async getSalesDetails(storeIds: number[], fromDate: string, toDate: string): Promise<SalesDetailsModel> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/dash/SalesDetails/${fromDate}/${toDate}${p}`),
            d => new SalesDetailsModel(d)
        );
    }

    public async getCAItemLevels(storeIds: number[], fromDate: string, toDate: string): Promise<object> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return this.received(
            await (await this.getApiV2()).get(`/dash/CAItemLevels/${fromDate}/${toDate}${p}`)
        );
    }
}