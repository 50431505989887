import BaseProvider from '@/providers/baseprovider';
import {globalState} from '@/states/global';
import moment from 'moment/moment';

export type materializeCall = 'all' | 'caGlobalUsers' | 'salesDetails' | 'caReportZ' | 'caSummarySales' |
                              'caSummaryPayments' | 'pointGlobalUsers';

export default class MaterializeProvider extends BaseProvider {
    public async pushMaterializeRequest(
        call: materializeCall,
        storeIds: number[],
        fromDate: string, toDate: string
    ): Promise<void> {
        let p: String = '';
        p = p.addParams("storeIds", storeIds, true);
        p = p !== '' ? `?${p}` : '';

        return await (await this.getApiV1()).post(`/materialize/${call}/${fromDate}/${toDate}${p}`);
    }

    public async sync24h (call: materializeCall): Promise<void> {
        await this.pushMaterializeRequest(
            'caReportZ',
            globalState.state.selected.storeIds,
            moment(new Date()).add(-1, 'day').format("yyyy-MM-DD"),
            moment(new Date()).format("yyyy-MM-DD")
        )
    }
}
