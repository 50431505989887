export default class Country {
    id?: number;
    name?: string;
    iso_code_2?: string;
    iso_code_3?: string;
    active?: boolean;
    vat_country_active?: boolean;

    constructor(init?: Partial<Country>) {
        Object.assign(this, init);
    }
}