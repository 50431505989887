import Organization from "@/models/organization"
import BaseProvider from "./baseprovider"

export default class OrganizationProvider extends BaseProvider {
    /**
     * fetchOrganization
     */
    public async fetchOrganization(): Promise<Organization> {
        const response = await (await this.getApiV2()).get('/organizations');

        return Promise.resolve(new Organization(response.data));
    }

    public async saveOrganization(organization: Organization): Promise<Organization> {
        const response = await (await this.getApiV2()).post(`/organizations/`, organization);

        const savedOrganization = this.getOrganization(response.data);

        return savedOrganization;
    }

    private getOrganization(data: any): Organization {
        const organization = new Organization(data);

        return organization;
    }
}