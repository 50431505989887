import Entity from '@/models/entity';
import Store from '@/models/store';
import GlobalModule from '@/models/globalmodule';

export default class GlobalUser {
    [key: string]: any;
    public id?: number;
    public template_global_user_id?: number;
    public name?: string;
    public login?: string;
    public user_domain?: string;
    public picture_content?: string;
    public language?: string;
    public template?: boolean;
    public active?: boolean = true;
    public creation_date?: Date;
    public modification_date?: Date;
    public dallaskey_code?: string;
    public phone_number?: string;
    public email?: string;
    public dashboard_fiche_id?: number;
    public mobile_number?: string;
    public asset_id?: number;
    public security_level?: number;
    public mercator_reps_id?: string;
    public fixed_cash_fund_amount?: number;
    public resource_id?: number;
    public idMapping?: string;
    public origin?: string;
    public creation_global_user_id?: number;
    public modification_global_user_id?: number;
    public national_number?: string;
    public sales_representative?: boolean;
    public mifare_code?: string;
    public quick_code?: number;
    public start_module?: number;
    public copy_permission_module?: boolean;
    public copy_permission_store?: boolean;
    public copy_permission_entity?: boolean;
    public copy_permission_journal?: boolean;
    public copy_permission_asset?: boolean;
    public copy_permission_task_type?: boolean;
    public copy_permission_task_grid?: boolean;
    public copy_permission_fiche?: boolean;
    public copy_permission_datawarehouse?: boolean;
    public copy_permission_listing?: boolean;
    public copy_permission_reporting?: boolean;
    public copy_permission_ribbon_button?: boolean;
    public copy_permission_fiche_dashboard?: boolean;
    public copy_permission_dashboard?: boolean;
    public fdm_configuration?: boolean;
    public color?: number;
    public fdm_close_cash_management?: boolean;
    public maximum_discount_rate?: number;
    public uid?: string;
    public organisation_id?: number;
    public is_admin?: boolean;
    public allow_backoffice?: boolean;
    public copy_permission_plug_and_pos?: boolean;
    public password_reset_token?: string;
    public glory_user_type_id?: number;
    public copy_permission_task_model?: boolean;
    public plugandpos_preferences?: string;
    public commercial_mail_template_id?: number;

    public entities?: any[] = [];
    public stores?: any[] = [];
    // public global_modules?: GlobalModule[] = [];
    public permissions?: any = {};

    constructor(init?: Partial<GlobalUser>) {
        Object.assign(this, init);
    }

    fromJson(init: any): GlobalUser {
        for (const key in init) {
            try {
                switch (key) {
                    case 'creation_date':
                    case 'modification_date':
                        this[key] = init[key] ? new Date(init[key]) : undefined;
                        break;
                    case 'entities':
                    case 'stores':
                        init[key].forEach((l: any, i: number) => {
                            const n = (key === 'entities') ? new Entity() :
                                      (key === 'stores') ? new Store() :
                                        new GlobalModule();
                            if ((this[key] ?? []).length == 0) { this[key] = [n.fromJson(l)] }
                            else { this[key]!.push(n.fromJson(l)); }
                        });
                        break;
                    default:
                        this[key] = init[key];
                }
            } catch(err) { console.warn(`GlobalUser fromJson conversion warning: ${key} is missing`)}
        }
        return this;
    }
}
