import { StoreTransferHeader, StoreTransferLine } from "@/models/document/storetransferdocument";

export class StoreTransferDirectLine extends StoreTransferLine {
    constructor(init?: Partial<StoreTransferDirectLine>) {
        super(init);
    }
}

export class StoreTransferDirectHeader extends StoreTransferHeader {
    constructor(init?: Partial<StoreTransferDirectHeader>) {
        super(init);
    }
}