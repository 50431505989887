export default class ZipCode {
    public id?: number;
    public zip_code?: string;
    public city?: string;
    public country_id?: number;
    public country_name?: string;

    constructor(init?: Partial<ZipCode>) {
        Object.assign(this, init);
    }
}