import {computed, onMounted, onUnmounted, ref} from 'vue';

export default function () {
    const windowWidth = ref(window.innerWidth);
    const windowHeight = ref(window.innerHeight);

    const onSizeChange = () => {
        windowWidth.value = window.innerWidth;
        windowHeight.value = window.innerHeight;
    }
    onMounted(() => {
        onSizeChange();
        window.addEventListener('resize', onSizeChange);
    })
    onUnmounted(() => {
        onSizeChange();
        window.removeEventListener('resize', onSizeChange);
    })

    const type = computed(() => {
        if (windowWidth.value < 576) return 'xs'
        if (windowWidth.value > 575 && windowWidth.value < 768) return 'sm'
        if (windowWidth.value > 767 && windowWidth.value < 992) return 'md'
        if (windowWidth.value > 991 && windowWidth.value < 1200) return 'lg'
        if (windowWidth.value > 1199) return 'xl'
        return 'xs';
    })

    const width = computed(() => windowWidth.value);
    const height = computed(() => windowHeight.value);

    return { width, height, type };
}