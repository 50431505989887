export default class GuestCountModel {
    [key: string]: any;
    group_date?: Date;
    store_id?: number;
    guest_count_in_progress?: number;
    guest_count_closed?: number;
    total_vat_included?: number;
    total_vat_excluded?: number;
    total_vat_included_in_progress?: number;
    total_vat_excluded_in_progress?: number;
    total_vat_included_cancelled?: number;
    total_vat_excluded_cancelled?: number;
    total_vat_included_closed?: number;
    total_vat_excluded_closed?: number;

    constructor(init: Partial<GuestCountModel> & Required<{}>) {
        Object.assign(this, init);
        if (init.group_date) this.group_date = new Date(init.group_date);
    }
}
