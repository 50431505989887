export default class Currency {
    [key: string]: any;

    id?: number;
    name_fr?: string;
    name_en?: string;
    name_nl?: string;
    iso_code?: string;

    constructor(init?: Partial<Currency>) {
        Object.assign(this, init);
    }

    fromJson(init: any): Currency {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch(err) { console.warn(`SaleLine fromJson conversion warning: ${key} is missing`)}
        }
        return this;
    }
}
