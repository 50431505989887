export default class MatrixTemplate {
    public id?: number;
    public uid?: string;
    public name?: string;
    public entity_id?: number;
    //public entities?: { matrix_template_id?: number, entity_id: number }[];


    constructor(init?: Partial<MatrixTemplate>) {
        Object.assign(this, init);
    }
}
