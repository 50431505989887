import BaseProvider from '@/providers/baseprovider';
import IntrastatCode from '@/models/intrastatcode';
import IntrastatTransactionType from '@/models/intrastattransactiontype';

export default class IntrastatProvider extends BaseProvider {
    public async fetchIntrastatCodes(): Promise<IntrastatCode[]> {
        const response = await (await this.getApiV2()).get('/Intrastat/Codes');

        const intrastatCodes = response.data.map((d: any) => new IntrastatCode(d));

        return intrastatCodes;
    }

    public async fetchIntrastatTransactionTypes(): Promise<IntrastatTransactionType[]> {
        const response = await (await this.getApiV2()).get('/Intrastat/TransactionTypes');

        const intrastatTransactionTypes = response.data.map((d: any) => new IntrastatTransactionType(d));

        return intrastatTransactionTypes;
    }
}