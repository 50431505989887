import {AscDesc} from '@/models/paged/pagedparams';

export default class PagedElements<T, F = { [key: string]: any; }> {
    public pageNumber: number = 1;
    public pageSize: number = 20;
    public totalPages: number = 0;
    public totalRecords: number = 0;
    public data?: T[];
    public sortField?: string;
    public sortOrder?: AscDesc;
    public filters?: F = {} as any;

    constructor(init?: Partial<PagedElements<T>>) {
        Object.assign(this, init);
    }

    public copy(copy: PagedElements<T, F>) {
        this.pageNumber = copy?.pageNumber;
        this.pageSize = copy?.pageSize;
        this.data = copy?.data;
        this.totalPages = copy?.totalPages;
        this.totalRecords = copy?.totalRecords;
        this.sortField = copy?.sortField;
        this.sortOrder = copy?.sortOrder;
        this.filters = copy?.filters;
    }
}
