export enum ConsumptionType {
    NONE,
    EATIN,
    TAKEAWAY,
    EATIN_AND_TAKEAWAY,
    RETAIL,
    DELIVERY,
    DELIVERY_AND_TAKEAWAY,
    DELIVERY_AND_EATIN_AND_TAKEAWAY,
    DELIVERY_AND_EATIN,
    CLOSE_CUSTOMER_ACCOUNT
}