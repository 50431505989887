import GlobalCategory from "../globalcategory";
import Listing from "../listing";

export default class ListingViewModel {
    public listing?: Listing;
    public globalCategory?: GlobalCategory;

    constructor(init?: Partial<ListingViewModel>) {
        Object.assign(this, init);
    }
}