export default class PaymentType {
  [key: string]: any
  public id?: number
  public general_account_id?: number
  public name_fr?: string
  public name_en?: string
  public name_nl?: string
  public retail?: boolean
  public transfer?: boolean
  public active?: boolean
  public use_cashdrawer?: boolean
  public use_terminal?: boolean
  public icon_content?: string
  public bank?: boolean
  public terminal_card_voucher_type?: number
  public fdm_payment_type?: number
  public fdm_type?: number
  public accounting?: boolean
  public free_payment?: boolean

  constructor(init?: Partial<PaymentType>) {
    Object.assign(this, init)
  }

  fromJson(init: any): PaymentType {
    for (const key in init) {
      try {
        switch (key) {
          default:
            this[key] = init[key]
        }
      } catch (err) {
        console.warn(
          `PaymentType fromJson conversion warning: ${key} is missing`
        )
      }
    }
    return this
  }
}
