import { CommercialHeader, CommercialLine, CommercialPayment } from "@/models/document/commercialdocument";
import { RegimeType } from "@/models/enums/regimetype.enum";
import { DocumentHeaderRepository, DocumentLineRepository, DocumentRepository, IDocumentObjectBuilder, IDocumentRepositoryParameters } from "@/models/repository/documentrepository";
import Item, { ItemItem } from "../item";
import NumberHelper from "@/helpers/numberhelper";
import useProvider from "@/hooks/provider";
import useLocalization from "@/hooks/localization";
import { v4 as uuidv4 } from "uuid";
import Currency from "../currency";
import CurrencyProvider from "@/providers/currencyprovider";
import { IAddLineParams, IItemDocumentLineRepository, ItemDocumentHeaderRepository, ItemDocumentLineItem, ItemDocumentLineRepository, ItemDocumentRepository, ItemPrice } from "./itemdocumentrepository";
import Store from "../store";
import PaymentTermProvider from "@/providers/paymenttermprovider";
import moment from "moment";

export type ICommercialLineRepository = CommercialLineRepository<CommercialLine>;
export type ICommercialHeaderRepository = CommercialHeaderRepository<CommercialHeader>;
export type ICommercialPaymentRepository = CommercialPaymentRepository<CommercialPayment>;
export type ICommercialRepository = CommercialRepository<ICommercialHeaderRepository, ICommercialLineRepository, ICommercialPaymentRepository>;

export abstract class CommercialHeaderRepository<H extends CommercialHeader> extends ItemDocumentHeaderRepository<H> {

    clear() {
        super.clear();
    }

    private _currency?: Currency;

    public async fetchCurrency(): Promise<Currency | undefined> {
        if (this.currencyId && (!this._currency || this._currency?.id != this.currencyId)) {
            this._currency = (await this.provider.currency.getCurrencyFromId(this.currencyId));
        }

        return this._currency;
    }

    //#region dutyActive

    get dutyActive(): boolean {
        return this.model.duty_active;
    }

    set dutyActive(value: boolean) {
        this.model.duty_active = value;

        this.edited = true;
    }

    //#endregion

    //#region reference

    get reference(): string | undefined {
        return this.model.reference;
    }

    set reference(value: string | undefined) {
        this.model.reference = value;

        this.edited = true;
    }

    //#endregion

    //#region deadline

    get deadline(): Date | undefined {
        return this.model.deadline;
    }

    set deadline(value: Date | undefined) {
        this.model.deadline = value;

        this.edited = true;
    }

    //#endregion

    //#region currencyId

    get currencyId(): number {
        return this.model.currency_id;
    }

    set currencyId(value: number) {
        this.model.currency_id = value;

        this.edited = true;
    }

    //#endregion

    //#region note

    get note(): string | undefined {
        return this.model.note;
    }

    set note(value: string | undefined) {
        this.model.note = value;

        this.edited = true;
    }

    //#endregion

    //#region storeId

    get storeId(): number | undefined {
        return this.model.store_id;
    }

    set storeId(value: number | undefined) {
        this.model.store_id = value;

        this.edited = true;
    }

    //#endregion

    //#region paymentTermId

    get paymentTermId(): number | undefined {
        return this.model.payment_term_id;
    }

    set paymentTermId(value: number | undefined) {
        this.setPaymentTermId(value);
    }

    setPaymentTermId(value: number | undefined) {
        this.model.payment_term_id = value;

        this.edited = true;
    }

    //#endregion

    //#region accounting

    get accounting(): boolean {
        return this.model.accounting;
    }

    set accounting(value: boolean) {
        this.model.accounting = value;

        this.edited = true;
    }

    //#endregion

    //#region documentStatusId

    get documentStatusId(): number | undefined {
        return this.model.document_status_id;
    }

    set documentStatusId(value: number | undefined) {
        this.model.document_status_id = value;

        this.edited = true;
    }

    //#endregion

    //#region priceListUid

    get priceListUid(): string | undefined {
        return this.model.price_list_uid;
    }

    set priceListUid(value: string | undefined) {
        this.setPriceListUid(value);
    }

    setPriceListUid(value: string | undefined) {
        this.model.price_list_uid = value;

        this.edited = true;
    }

    //#endregion

    //#region regimeType

    get regimeType(): RegimeType {
        return this.model.regime_type;
    }

    set regimeType(value: RegimeType) {
        this.setRegimeType(value);
    }

    setRegimeType(value: RegimeType) {
        this.model.regime_type = value;

        this.edited = true;
    }

    //#endregion

    private _store?: Promise<Store>;

    public async fetchStore(): Promise<Store> {
        if (!this._store) {

            this._store = new Promise<Store>((resolve, reject) => {
                try {
                    this.fetchJournal().then(journal => {
                        resolve(this.provider.store.getStoreFromIdV2(Number(journal.store_id)));
                    });
                } catch (error) {
                    reject(error);
                }
            });

            console.log("fetchStore", this._store)
        }

        return this._store;
    }

    constructor(headerBuilder: IDocumentObjectBuilder<H>, init?: Partial<CommercialHeader>) {
        super(headerBuilder, init);
    }

    compute(lines: IItemDocumentLineRepository[]): void {
        let totalVATIncluded: number = 0;
        let totalVATExcluded: number = 0;
        const totalVatExcludedPaymentDiscountException: number = 0;
        const vatRates: Map<number, number> = new Map<number, number>();
        const roundedDocument: number = 2;

        if (this.regimeType == RegimeType.VATIncluded) {
            lines.forEach(line => {
                const commercialLine = line as ICommercialLineRepository;

                if (!commercialLine.model.item_uid) return;

                totalVATExcluded += commercialLine.model.total_net_vat_excluded ?? 0;
                totalVATIncluded += commercialLine.model.total_net_vat_included ?? 0;
            });
        }
        else {
            lines.forEach(line => {
                const commercialLine = line as ICommercialLineRepository;

                if (!commercialLine.model.item_uid) return;

                const vatRate: number = this.computeVatRate(commercialLine);

                if (!vatRates.has(vatRate)) {
                    vatRates.set(vatRate, commercialLine.model.total_net_vat_excluded ?? 0);
                }
                else {
                    vatRates.set(vatRate, (vatRates.get(vatRate) ?? 0) + (commercialLine.model.total_net_vat_excluded ?? 0));
                }

                // TODO

                //if ((line as CommercialLineDataRow) != null && !Convert.ToBoolean((line as CommercialLineDataRow).item["payment_discount"]))
                //{
                //    totalVatExcludedPaymentDiscountException += Convert.ToDecimal(line["total_net_vat_excluded"]);
                //}
                //else
                //{
                totalVATExcluded += (commercialLine.model.total_net_vat_excluded ?? 0);
                //}
            });



            vatRates.forEach((totalVatRate, vatRate) => {
                switch (this.regimeType) {
                    case RegimeType.Normal:
                        totalVATIncluded += NumberHelper.round(totalVatRate, roundedDocument) + (NumberHelper.round(totalVatRate, roundedDocument) * vatRate);
                        break;

                    case RegimeType.ImportExport:
                    case RegimeType.Exempted:
                    case RegimeType.EEC:
                    case RegimeType.Cocontractant:
                        totalVATIncluded += NumberHelper.round(totalVatRate, roundedDocument);
                        break;

                    case RegimeType.VATIncluded:
                        // noting here
                        break;
                }
            });
        }

        //switch (paymentDiscountType)
        //{
        //    case PaymentDiscountType.None:
        //    case PaymentDiscountType.NotDeducted:
        this.model.total_vat_included = NumberHelper.round(totalVATIncluded, roundedDocument);
        this.model.total_vat_excluded = NumberHelper.round(totalVATExcluded + totalVatExcludedPaymentDiscountException, roundedDocument);
        //        break;
        //    case PaymentDiscountType.Deducted:
        //        this.row["total_vat_included"] = Math.Round(totalVATIncluded - (totalVATExcluded * paymentDiscountRate), Convert.ToInt16(this.journalEngine["rounded_document"]));
        //        this.row["total_vat_excluded"] = Math.Round(totalVatExcludedPaymentDiscountException + CommercialHelper.applyDiscountRateToAmount(totalVATExcluded, paymentDiscountRate), Convert.ToInt16(this.journalEngine["rounded_document"]));
        //        break;
        //}
    }

    private computeVatRate(line: ICommercialLineRepository): number {
        // TODO
        if (/*!Convert.ToBoolean((line as CommercialLineDataRow).item["payment_discount"]) || */ this.regimeType == RegimeType.VATIncluded)
            return line.vatRate ?? 0;

        //switch (this.paymentDiscountType)
        //{
        //    case PaymentDiscountType.None:
        return line.vatRate ?? 0;
        //    case PaymentDiscountType.NotDeducted:
        //    case PaymentDiscountType.Deducted:
        //        return CommercialHelper.applyDiscountRateToAmount(Convert.ToDecimal(line["vat_rate"]), paymentDiscountRate);
        //    default:
        //        throw new Exception(strings.exception_payment_discount_not_defined);
        //}
    }

    fromJson(init: any): ICommercialHeaderRepository {
        super.fromJson(init);

        console.log("CommercialHeaderRepository.fromJson");

        return this;
    }
}

export abstract class CommercialLineRepository<L extends CommercialLine> extends ItemDocumentLineRepository<L> {

    clear() {
        super.clear();
    }

    //#region note

    get note(): string | undefined {
        return this.model.note;
    }

    set note(value: string | undefined) {
        this.setNote(value);
    }

    setNote(value: string | undefined) {
        this.model.note = value;

        this.edited = true;
    }

    //#endregion

    //#region vatRate

    get vatRate(): number | undefined {
        return this.model.vat_rate;
    }

    set vatRate(value: number | undefined) {
        if (this.model.vat_rate == value) return; // TODO : remove this when VATRateDropDown is fixed

        this.model.vat_rate = value;

        this.edited = true;
    }

    //#endregion

    //#region discountRate

    get discountRate(): number | undefined {
        return this.model.discount_rate;
    }

    set discountRate(value: number | undefined) {
        this.model.discount_rate = value;

        this.edited = true;
    }

    //#endregion

    constructor(lineBuilder: IDocumentObjectBuilder<L>, init?: Partial<CommercialLine>) {
        super(lineBuilder, init);
    }

    compute(regimeType: RegimeType): void {
        if (!this.model.item_uid)
            return;

        let priceVatIncluded: number = 0;
        let priceVatExcluded: number = 0;
        const round: number = 2;
        const roundResult: boolean = false;

        // switch (Convert.ToInt32(this.Journal.RoundedDocumentType))
        // {
        //     // Rounded line by line
        //     case 1:
        //         round = Convert.ToInt16(this.Journal.RoundedLine);
        //         roundResult = true;
        //         break;
        // }

        const quantity: number = Number(this.quantity);

        switch (regimeType) {
            case RegimeType.Normal:
                priceVatExcluded = this.unitPrice ?? 0;

                if (roundResult) {
                    this.model.total = NumberHelper.round(this.getLineTotalWithDiscount(priceVatExcluded * quantity), round);
                    this.model.total_net_vat_excluded = NumberHelper.round(this.model.total, round);
                    this.model.total_net_vat_included = NumberHelper.round(NumberHelper.applyVatRateToAmount(NumberHelper.round(this.model.total, round), this.vatRate ?? 0), round);
                }
                else {
                    this.model.total = NumberHelper.round(this.getLineTotalWithDiscount(priceVatExcluded * quantity), round);
                    this.model.total_net_vat_excluded = NumberHelper.round(this.model.total, round);
                    this.model.total_net_vat_included = NumberHelper.round(NumberHelper.applyVatRateToAmount(this.model.total, this.vatRate ?? 0), round);
                }
                break;

            case RegimeType.ImportExport:
            case RegimeType.Exempted:
            case RegimeType.EEC:
            case RegimeType.Cocontractant:
                priceVatExcluded = this.unitPrice ?? 0;

                if (roundResult) {
                    this.model.total = NumberHelper.round(this.getLineTotalWithDiscount(priceVatExcluded * quantity), round);
                    this.model.total_net_vat_excluded = NumberHelper.round(this.model.total, round);
                    this.model.total_net_vat_included = NumberHelper.round(this.model.total_net_vat_excluded, round);
                }
                else {
                    this.model.total = NumberHelper.round(this.getLineTotalWithDiscount(priceVatExcluded * quantity), round);
                    this.model.total_net_vat_excluded = NumberHelper.round(this.model.total, round);
                    this.model.total_net_vat_included = this.model.total_net_vat_excluded;
                }

                break;

            case RegimeType.VATIncluded:
                if (roundResult) {
                    priceVatIncluded = this.unitPrice ?? 0;
                    priceVatExcluded = NumberHelper.removeVatRateToAmount(priceVatIncluded, this.vatRate ?? 0);

                    this.model.total = NumberHelper.round(this.getLineTotalWithDiscount(priceVatIncluded * quantity), round);
                    this.model.total_net_vat_excluded = NumberHelper.round(this.getLineTotalWithDiscount(priceVatExcluded * quantity), round);
                    this.model.total_net_vat_included = this.model.total;
                }
                else {
                    priceVatIncluded = this.unitPrice ?? 0;
                    priceVatExcluded = NumberHelper.removeVatRateToAmount(priceVatIncluded, this.vatRate ?? 0);

                    this.model.total = NumberHelper.round(this.getLineTotalWithDiscount(priceVatIncluded * quantity), round);
                    this.model.total_net_vat_excluded = NumberHelper.round(this.getLineTotalWithDiscount(priceVatExcluded * quantity), round);
                    this.model.total_net_vat_included = this.model.total;
                }

                break;
        }
    }

    getLineTotalWithDiscount(total: number): number {
        const newTotal: number = NumberHelper.applyDiscountRateToAmount(total, this.discountRate ?? 0);

        return newTotal;
    }

    fromJson(init: any): CommercialLineRepository<L> {
        super.fromJson(init);

        console.log("CommercialLineRepository.fromJson");

        return this;
    }
}

export abstract class CommercialPaymentRepository<P extends CommercialPayment> {
    model: P;

    clear() {
        this.edited = false;
    }

    //#region edited

    private _edited: boolean = false;

    public get edited(): boolean {
        return this._edited;
    }

    protected set edited(value: boolean) {
        this._edited = value;
    }

    //#endregion

    //#region paymentTypeId

    get paymentTypeId(): number | undefined {
        return this.model.payment_type_id;
    }

    set paymentTypeId(value: number | undefined) {
        this.model.payment_type_id = value;

        this.edited = true;
    }

    //#endregion

    //#region amount

    get amount(): number {
        return this.model.amount;
    }

    set amount(value: number) {
        this.model.amount = value;

        this.edited = true;
    }

    //#endregion

    //#region date

    get date(): Date | undefined {
        return this.model.date;
    }

    set date(value: Date | undefined) {
        this.model.date = value;

        this.edited = true;
    }

    //#endregion

    constructor(paymentBuilder: IDocumentObjectBuilder<P>, init?: Partial<CommercialPayment>) {
        this.model = new paymentBuilder();

        Object.assign(this.model, init);
    }

    fromJson(init: any): CommercialPaymentRepository<P> {
        console.log("CommercialPaymentRepository.fromJson");

        this.model.fromJson(init);

        return this;
    }
}

export interface ICommercialRepositoryParameters<H extends ICommercialHeaderRepository, L extends ICommercialLineRepository, P extends ICommercialPaymentRepository> extends IDocumentRepositoryParameters<H, L> {
    payments?: P[];
}

export class CommercialItemPrice extends ItemPrice {
    discountRate?: number;
    priceListUid?: string | undefined;

    constructor(init?: Partial<CommercialItemPrice>) {
        super(init);

        Object.assign(this, init);
    }

    public get total(): number {
        return NumberHelper.applyDiscountRateToAmount(this.price ?? 0, this.discountRate ?? 0);
    }
}

export abstract class CommercialRepository<H extends ICommercialHeaderRepository, L extends ICommercialLineRepository, P extends ICommercialPaymentRepository> extends ItemDocumentRepository<H, L> {
    payments: P[];

    clear() {
        super.clear();

        this.unlocked = false;

        this.payments.forEach(p => p.clear());
        this.payments = [];
    }

    private paymentBuilder: IDocumentObjectBuilder<ICommercialPaymentRepository>;

    get isEdited(): boolean {
        return super.isEdited || this.payments.filter(p => p.edited).length > 0;
    }

    get totalAmountUnpaid(): number {
        return NumberHelper.round(this.totalAmountLines - this.totalAmountPayments, 2);
    }

    get totalAmountPayments(): number {
        return NumberHelper.round(this.payments.reduce((sum, current) => sum + current.amount, 0), 2);
    }

    get totalAmountLines(): number {
        return NumberHelper.round(this.lines.reduce((sum, current) => sum + current.total, 0), 2);
    }

    private _unlocked: boolean = false;
    get unlocked(): boolean { return this._unlocked; }
    private set unlocked(value: boolean) { this._unlocked = value; }

    constructor(
        headerBuilder: IDocumentObjectBuilder<H>,
        lineBuilder: IDocumentObjectBuilder<L>,
        paymentBuilder: IDocumentObjectBuilder<P>,
        header?: H,
        lines?: L[],
        payments?: P[],
    ) {
        super(headerBuilder, lineBuilder, header, lines);

        this.paymentBuilder = paymentBuilder;

        this.payments = payments ?? [];
    }

    public async fetchStore(): Promise<Store> {
        return this.header.fetchStore();
    }

    newItemPrice(): ItemPrice {
        return new CommercialItemPrice({
            price: 0,
            discountRate: 0,
        })
    }

    //#region regimeType

    protected abstract setRegimeType(value: RegimeType): void;

    get regimeType(): RegimeType {
        return this.header.regimeType;
    }

    set regimeType(value: RegimeType) {
        this.setRegimeType(value);
    }

    //#endregion

    //#region paymentTermId

    get paymentTermId(): number | undefined {
        return this.header.paymentTermId;
    }

    set paymentTermId(value: number | undefined) {
        this.setPaymentTermId(value);
    }

    setPaymentTermId(value: number | undefined) {
        this.header.paymentTermId = value;

        this.getDeadlineFromDate(this.header.date).then(deadline => {
            this.header.deadline = deadline;
        });
    }

    //#endregion

    //#region priceListUid

    get priceListUid(): string | undefined {
        return this.header.priceListUid;
    }

    set priceListUid(value: string | undefined) {
        this.setPriceListUid(value);
    }

    //#endregion

    private setPriceListUid(value: string | undefined) {
        this.header.priceListUid = value;

        this.lines.forEach(line => {
            line.fetchItem().then(item => {
                if (!item) return;

                const itemPrice = this.getItemPriceFromPriceList(item, this.header.priceListUid);

                this.setLineItemPrice(line, itemPrice);
            });
        });
    }

    unlock() {
        this.header.accounting = false;

        this.unlocked = true;
    }

    async getDeadlineFromDate(date: Date | undefined): Promise<Date | undefined> {
        if (!this.paymentTermId)
            return Promise.resolve(this.header.deadline ? this.header.deadline : undefined);

        const provider = useProvider();

        const paymentTerm = await provider.paymentTerm.getPaymentTermFromId(this.paymentTermId);

        if (!paymentTerm) return Promise.reject(undefined);

        if (!date) return Promise.resolve(undefined);

        const nbDays = paymentTerm.nb_days;

        let deadline: Date;

        if (paymentTerm.end_of_month) {
            if (nbDays % 30 == 0) {
                // multiple of 30 (30 = 1 month)
                const nbMonths: number = nbDays / 30;

                const newDate = moment(date).add(nbMonths, 'months');

                deadline = new Date(newDate.year(), newDate.month(), newDate.daysInMonth());
            }
            else {
                const newDate_1 = moment(date).add(nbDays, 'days');

                deadline = moment(new Date(newDate_1.year(), newDate_1.month(), 1)).add(1, 'month').add(-1, 'days').toDate();
            }
        }
        else {
            deadline = moment(date).add(nbDays, 'days').toDate();
        }

        return await Promise.resolve(deadline);
    }

    computeLine(line: L): void {
        line.compute(this.regimeType);
    }

    public getItemPrice(item: ItemDocumentLineItem): ItemPrice {
        return this.priceListUid ? this.getItemPriceFromPriceList(item, this.priceListUid) : this.getItemPriceFromItem(item);
    }

    private getItemPriceFromPriceList(item: ItemDocumentLineItem, priceListUid: string | undefined): ItemPrice {
        const itemPrice = new CommercialItemPrice();

        if (priceListUid) {
            const priceList = item?.pricelists?.find(p => p.price_list_uid == priceListUid);

            if (priceList && (priceList.price_vat_included || priceList.price_vat_excluded || priceList.discount_rate)) {

                const priceVATIncluded = priceList.price_vat_included ? Number(priceList.price_vat_included) : item.price_vat_included ? Number(item.price_vat_included) : undefined;
                const priceVATExcluded = priceList.price_vat_excluded ? Number(priceList.price_vat_excluded) : item.price_vat_excluded ? Number(item.price_vat_excluded) : undefined;

                if (priceList.discount_rate) {
                    itemPrice.discountRate = Number(priceList.discount_rate);
                }

                itemPrice.price = this.getPriceFromPrices(priceVATIncluded, priceVATExcluded);
            }
        } else {
            itemPrice.price = this.getPriceFromPrices(item.price_vat_included, item.price_vat_excluded);
        }

        return itemPrice;
    }

    public getPriceFromPrices(priceVATIncluded: number | undefined, priceVATExcluded: number | undefined): number | undefined {
        switch (this.header.regimeType) {
            case RegimeType.Normal:
            case RegimeType.ImportExport:
            case RegimeType.Exempted:
            case RegimeType.EEC:
            case RegimeType.Cocontractant:

                if (priceVATExcluded == undefined) return undefined;

                return Number(priceVATExcluded);

            case RegimeType.VATIncluded:

                if (priceVATIncluded == undefined) return undefined;

                return Number(priceVATIncluded);

            default:
                throw new Error("Regime not defined.");
        }
    }

    setLineVATRate(line: L, vatRate: number | undefined) {
        line.vatRate = vatRate;

        this.computeLineAndHeader(line);
    }

    setLineDiscountRate(line: L, discountRate: number | undefined) {
        line.discountRate = discountRate;

        this.computeLineAndHeader(line);
    }

    setLineItemPrice(line: L, itemPrice: ItemPrice) {
        super.setLineItemPrice(line, itemPrice);

        this.setLineDiscountRate(line, Number((itemPrice as CommercialItemPrice).discountRate ?? 0));
    }

    protected addPayment(payment: P): number {
        const index = this.payments.push(payment);

        this.edited = true;

        return index;
    }

    public async searchItems(search: string): Promise<any[]> {
        const entity = await this.fetchEntity();
        const provider = useProvider();

        return await provider.sale.searchItems(
            [entity.id ?? 0],
            search.trim(),
            this.header.storeId ?? 0,
            this.priceListUid
        );
    }

    removePayment(payment: P): P[] {
        const deleted = this.payments.splice(this.payments.indexOf(payment), 1);

        this.edited = true;

        return deleted;
    }

    fromJson(init: any): CommercialRepository<H, L, P> {

        super.fromJson(init);

        console.log("CommercialRepository.fromJson", init);

        try {
            if (init['payments']) {
                init['payments'].forEach((p: any, i: number) => {
                    const n = new this.paymentBuilder();
                    const value = n.fromJson(p);

                    this.payments.push(value as P);
                });
            }

        } catch (err) { console.warn(`CommercialRepository.fromJson conversion warning: ${'/'} is missing ${err}`) }

        return this;
    }

    toJson() {
        const json = super.toJson();

        json['payments'] = this.payments.map((p) => p.model.toJson())

        if (this.unlocked) {
            json.header.accounting = true;
        }

        return json;
    }
}