export default class GlobalModule {
    [key: string]: any;
    public id?: number;
    public name?: string;
    public description_fr?: string;
    public description_en?: string;
    public description_nl?: string;
    public restriction?: boolean;

    constructor(init?: Partial<GlobalModule>) {
        Object.assign(this, init);
    }

    fromJson(init: any): GlobalModule {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch(err) { console.warn(`GlobalModule fromJson conversion warning: ${key} is missing`)}
        }
        return this;
    }
}
