export default class Allergen {
    public id?: number;
    public name_fr?: string;
    public name_en?: string;
    public name_nl?: string;
    public image_content?: any;

    constructor(init?: Partial<Allergen>) {
        Object.assign(this, init);
    }
}