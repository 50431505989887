<template>
  <div class="bo-selector bo--selector-store">
    <i class="fas fa-store-alt" />
    <MultiSelect
        aria-autocomplete="none"
        class="bo-fullwidth" style="z-index: 10000"
        :options="globalState.getters.availableStores"
        :optionLabel="localize.field('name')"
        placeholder="Store"
        optionValue="id"
        v-model="selectedStores"
    >
    </MultiSelect>
  </div>
</template>
<script>
import {computed, defineComponent, onMounted, reactive, watch} from "vue";
import {globalState} from '@/states/global';
import filters from "@/helpers/filters";
import useLocalization from "@/hooks/localization";
export default defineComponent({
  setup() {
    const localize = useLocalization();

    const selectedStores = computed({
      get: () => { return globalState.state.selected.storeIds; },
      set: (v) => { globalState.dispatch('setStoreIds', v); }
    });

    return {
      filters,
      globalState,
      localize,
      selectedStores
    }
  }
})
</script>

<!--<template>
  <div class="bo-selector bo--selector-store">
    <i class="fas fa-store-alt" />
    <MultiSelect
      aria-autocomplete="none"
      class="bo-fullwidth"
      v-model="selectedStores.value"
      :options="stores.value"
      optionLabel="name_fr"
      placeholder="Store"
      dataKey="id"
      optionValue="id"
    >
    </MultiSelect>
  </div>
  <!- Stores : {{ selectedStores.value }} ->
</template>

<script>
import { defineComponent, onMounted, reactive, watch } from "vue";
 import { globalState } from '@/states/global';
import {authState} from '@/states/auth';

export default defineComponent({
  setup() {
    const stores = reactive([]);
    const selectedStores = reactive([]);

    const refreshStores = async () => {
      stores.value = (await globalState.dispatch("fetchStores"))
          .filter((s) => globalState.state.selected.entityIds.some((id) => id === s.entity_id) &&
                         (authState.state.globalUser?.is_admin || s.resupply)
      );

      selectedStores.value = stores.value.map((x) => x.id);
    };

    watch(
      () => selectedStores.value,
      (newVal, oldVal) => {
        globalState.dispatch("setStoreIds", newVal);
      }
    );

    watch(
      () => globalState.state.selected.entityIds,
      async (newVal, oldVal) => {
        await refreshStores();
      }
    );

    onMounted(async () => {
      // await refreshStores();
    });

    return { stores, selectedStores };
  },
});
</script>
-->