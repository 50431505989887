import Tag from "@/models/tag";
import BaseProvider from "./baseprovider"

export default class TagProvider extends BaseProvider {
    public async fetchTags(): Promise<Tag[]> {
        const response = await (await this.getApiV2()).get('/Tags');

        const tags = response.data.map((d: any) => new Tag(d));

        return tags;
    }
}