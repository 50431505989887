export default class VAT {
    [key: string]: any;

    id?: number;
    name_fr?: string;
    name_nl?: string;
    name_en?: string;
    vat_rate?: number;
    country_id?: number;
    code?: string;

    constructor(init?: Partial<VAT>) {
        Object.assign(this, init);
    }
}