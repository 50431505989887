import ItemCategory from "@/models/itemcategory";
import BaseProvider from "./baseprovider"
import { PagedParams } from "@/models/paged/pagedparams";
import PagedItemCategories from "@/models/paged/pageditemcategories";

export default class ItemCategoryProvider extends BaseProvider {
    public async fetchItemCategories(params?: {
        entityIds?: Array<number>,
        types?: Array<number>
    }): Promise<ItemCategory[]> {
        let p: String = '';
        p = p.addParams("entityIds", params?.entityIds, true);
        p = p.addParams("type", params?.types, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/ItemCategories${p}`);

        const itemCategories = response.data.map((d: any) => new ItemCategory(d));

        return itemCategories;
    }
    
    public async fetchPagedItemCategories(
        params?: {
            entityIds?: Array<number>,
            types?: Array<number>
        },
        pagedParams?: PagedParams
    ): Promise<PagedItemCategories> {
        let p: String = '';
        p = p.addParams("entityIds", params?.entityIds, true);
        p = p.addParams("type", params?.types, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/ItemCategories${p}`);

        const pagedItems = new PagedItemCategories(response.data);

        return pagedItems;
    }

    public async getItemCategoryFromUid(itemCategoryUid: string): Promise<ItemCategory> {
        const response = await (await this.getApiV2()).get(`/itemcategories/${itemCategoryUid}`);

        const itemCategories = this.getItemCategory(response.data);

        return itemCategories;
    }

    public async getItemCategoryFromId(itemCategoryId: number): Promise<ItemCategory> {
        const response = await (await this.getApiV2()).get(`/itemcategories/${itemCategoryId}`);

        const itemCategories = this.getItemCategory(response.data);

        return itemCategories;
    }

    public async saveItemCategory(itemCategory: ItemCategory): Promise<ItemCategory> {
        const response = await (await this.getApiV2()).post(`/itemcategories/`, itemCategory);

        const items = this.getItemCategory(response.data);

        return items;
    }

    private getItemCategory(data: any): ItemCategory {
        const itemCategory = new ItemCategory(data);

        return itemCategory;
    }
}
