import PagedPropositions from "@/models/paged/pagedpropositions";
import Proposition from "@/models/proposition";
import PropositionTo from "@/models/propositionto";
import BaseProvider from "./baseprovider"
import ItemStore from '@/models/itemstore';
import ItemProvider from '@/providers/itemprovider';
import { PagedParams } from '@/models/paged/pagedparams';
import PagedItemPropositionLinks from "@/models/paged/pageditempropositionlinks";

export interface IItemPropositionLinks {
    item_uids: string[];
    propositions: IItemPropositionParams[];
}

export interface IItemPropositionParams {
    proposition_id: number;
    order_number: number;
}

export default class PropositionProvider extends BaseProvider {
    public async fetchPropositions(entityIds: number[]): Promise<Proposition[]> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/propositions/entities${p}`);

        const propositions = response.data.map((d: any) => new Proposition(d));

        return propositions;
    }

    public async fetchPagedPropositions(entityIds: number[], pagedParams?: PagedParams): Promise<PagedPropositions> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            pageNumber: 1,
            pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc',
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/propositions${p}`);

        const pagedItems = new PagedPropositions(response.data);

        return pagedItems;
    }

    public async getPropositionFromUid(propositionUid: string): Promise<Proposition> {
        const response = await (await this.getApiV2()).get(`/propositions/${propositionUid}`);

        const propositions = this.getProposition(response.data);

        return propositions;
    }

    public async saveProposition(proposition: Proposition): Promise<Proposition> {
        const response = await (await this.getApiV2()).post(`/propositions/`, proposition);

        const items = this.getProposition(response.data);

        return items;
    }

    private getProposition(data: any): Proposition {
        const proposition = new Proposition(data);

        proposition.propositions_to = data.propositions_to?.sort((a: any, b: any) => a.order_number - b.order_number);
        proposition.propositions_to?.forEach(async (to: any, idx: number) => {
            const r = new PropositionTo(to);
            r.stores = r.to_item_uid ? await (new ItemProvider().fetchItemStores(r.to_item_uid)) : [];
            proposition.propositions_to![idx] = r;
        });

        return proposition;
    }

    public async searchItems(entityIds: number[], search: string): Promise<Array<any>> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addParams("search", [search]);
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/propositions/searchitems${p}`);

        for (let idx = 0; idx < response.data?.length ?? 0; idx++) {
            if ("stores" in response.data[idx]) {
                response.data[idx].stores.map((d: any) => new ItemStore(d));
            } else {
                response.data[idx].stores = await (new ItemProvider().fetchItemStores(response.data[idx].item_id)) ?? [];
            }
        }

        return response.data;
    }

    public async fetchItemPropositionLinks(
        entityIds: number[],
        pagedParams: PagedParams
    ): Promise<PagedItemPropositionLinks> {
        let p: String = '';
        p = p.addParams("entityIds", entityIds, true);
        p = p.addPagedParams(pagedParams, {
            // pageNumber: 1,
            // pageSize: 25,
            sortField: 'name',
            sortOrder: 'asc'
        });
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`propositions/itemlinks${p}`);

        return new PagedItemPropositionLinks(response?.data);
    }

    public async saveItemPropositionLinks(params: IItemPropositionLinks) {
        const response = await (await this.getApiV2()).post(`/propositions/itemlinks/link`, params);

        return response.data;
    }

    public async unlinkItemPropositionLinks(params: IItemPropositionLinks) {
        const response = await (await this.getApiV2()).post(`/propositions/itemlinks/unlink`, params);

        return response.data;
    }

    public async unlinkAllItemPropositionLinks(params: IItemPropositionLinks) {
        const response = await (await this.getApiV2()).post(`/propositions/itemlinks/unlinkall`, params);

        return response.data;
    }
}
