import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7620ab7b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "organization-system-banner__container"
}
const _hoisted_2 = {
  key: 0,
  class: "ml-8 color-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _component_LoginAsDialog = _resolveComponent("LoginAsDialog")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Toolbar, {
          class: _normalizeClass({ 'background-gradient-gold': _ctx.active })
        }, {
          start: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass({ 'color-white': _ctx.active })
            }, _toDisplayString(_ctx.authState.state.organization?.name), 3),
            (_ctx.active)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createTextVNode(_toDisplayString(_ctx.$filters.capitalizeFirstLetter(_ctx.$t("logged_as"))) + " ", 1),
                  _createVNode(_component_Chip, {
                    label:  _ctx.authState.state.globalUser?.name ?? '',
                    class: "background-gradient-silver"
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true)
          ]),
          end: _withCtx(() => [
            (_ctx.active)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  class: "p-button p-button-rounded background-gradient-gold",
                  icon: "fas fa-right-from-bracket",
                  onClick: _ctx.logoutAsButtonClicked,
                  label: _ctx.$filters.capitalizeFirstLetter(_ctx.$t('back_to_my_session')),
                  iconPos: "right"
                }, null, 8, ["onClick", "label"]))
              : (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  class: "p-button p-button-rounded background-gradient-blue",
                  icon: "fas fa-arrow-right",
                  onClick: _ctx.loginAsButtonClicked,
                  label: _ctx.$filters.capitalizeFirstLetter(_ctx.$t('login_as')),
                  iconPos: "right"
                }, null, 8, ["onClick", "label"]))
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_LoginAsDialog, {
          ref: "loginAsDialog",
          onLoginAsClicked: _ctx.loginAsClicked
        }, null, 8, ["onLoginAsClicked"])
      ]))
    : _createCommentVNode("", true)
}