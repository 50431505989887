import { PurchaseHeader, PurchaseLine, PurchasePayment } from "@/models/document/purchasedocument";
import { CommercialRepository, CommercialLineRepository, CommercialHeaderRepository, ICommercialRepositoryParameters, CommercialPaymentRepository, ICommercialPaymentRepository, CommercialItemPrice } from "@/models/repository/commercialrepository";
import { v4 as uuidv4 } from "uuid";
import Supplier from "@/models/supplier";
import SupplierProvider from "@/providers/supplierprovider";
import PriceListProvider from "@/providers/pricelistprovider";
import Item from "../item";
import { IAddLineParams, ItemDocumentLineItem, ItemPrice } from "./itemdocumentrepository";
import { RegimeType } from "../enums/regimetype.enum";

export class PurchaseHeaderRepository extends CommercialHeaderRepository<PurchaseHeader> {

    clear() {
        super.clear();

        this._supplier = undefined;
    }

    private _supplier?: Supplier;

    public async fetchSupplier(): Promise<Supplier | undefined> {
        if (this.supplierUid && (!this._supplier || this._supplier?.uid != this.supplierUid)) {
            this._supplier = (await new SupplierProvider().getSupplierFromUid(this.supplierUid));
        }

        return this._supplier;
    }

    //#region supplierUid

    get supplierUid(): string | undefined {
        return this.model.supplier_uid;
    }

    set supplierUid(value: string | undefined) {
        this.model.supplier_uid = value;
        this._supplier = undefined;

        this.edited = true;
    }

    //#endregion

    //#region intrastatTransactionTypeId

    get intrastatTransactionTypeId(): number | undefined {
        return this.model.intrastat_transaction_type_id;
    }

    set intrastatTransactionTypeId(value: number | undefined) {
        this.model.intrastat_transaction_type_id = value;

        this.edited = true;
    }

    //#endregion

    constructor(init?: Partial<PurchaseHeader>) {
        super(PurchaseHeader, init);
    }
}

export class PurchaseLineRepository extends CommercialLineRepository<PurchaseLine> {

    constructor(init?: Partial<PurchaseLine>) {
        super(PurchaseLine, init);
    }
}

export class PurchasePaymentRepository extends CommercialPaymentRepository<PurchasePayment> {

    constructor(init?: Partial<PurchasePayment>) {
        super(PurchasePayment, init);
    }
}

interface IPurchaseRepositoryParameters extends ICommercialRepositoryParameters<PurchaseHeaderRepository, PurchaseLineRepository, PurchasePaymentRepository> {

}

export class PurchaseRepository extends CommercialRepository<PurchaseHeaderRepository, PurchaseLineRepository, ICommercialPaymentRepository> {
    constructor(params?: IPurchaseRepositoryParameters) {
        super(PurchaseHeaderRepository, PurchaseLineRepository, PurchasePaymentRepository, params?.header, params?.lines, params?.payments);
    }

    //#region supplierUid

    get supplierUid(): string | undefined {
        return this.header.supplierUid;
    }

    set supplierUid(value: string | undefined) {
        this.setSupplierUid(value);
    }

    private setSupplierUid(value: string | undefined) {
        this.header.supplierUid = value;

        this.header.fetchSupplier().then((s: Supplier | undefined) => {
            if (s) {
                this.regimeType = Number(s.regime_type);
                this.header.dutyActive = s.duty_active;

                if (s.payment_term_id) {
                    this.setPaymentTermId(s.payment_term_id);
                } else {
                    this.setPaymentTermId(undefined);
                }

                if (s.price_list_id) {
                    // TODO : retrieve CUSTOMER.price_list_uid instead of fetching all price lists
                    this.fetchEntity().then((entity) => {
                        new PriceListProvider().fetchPriceLists([entity.id ?? 0]).then(priceLists => {
                            const priceList = priceLists.find(p => Number(p.id) == Number(s.price_list_id));

                            if (priceList) {
                                this.priceListUid = priceList.uid;
                            }
                        });
                    });
                } else {
                    this.priceListUid = undefined;
                }
            }
        });
    }

    //#endregion

    protected setRegimeType(value: RegimeType): void {
        this.header.regimeType = value;

        this.compute();
    }

    getItemPriceFromItem(item: Item): ItemPrice {
        return new CommercialItemPrice({
            price: this.getPriceFromPrices(undefined, item.purchase_price),
            discountRate: 0,
        });
    }

    async newLineFromItem(item: ItemDocumentLineItem, params: IAddLineParams): Promise<PurchaseLineRepository> {
        const line = await super.newLineFromItem(item, params);

        line.vatRate = item.vat_rate;

        return line;
    }

    getParentLine(childLine: PurchaseLineRepository): PurchaseLineRepository | undefined {
        return this.lines.find(l => l.model.uid == childLine.model.parent_purchase_line_uid);
    }

    setParentLine(childLine: PurchaseLineRepository, parentLine: PurchaseLineRepository): void {
        childLine.model.parent_purchase_line_uid = parentLine.model.uid;
    }

    getChildLines(line: PurchaseLineRepository): PurchaseLineRepository[] {
        return this.lines.filter(l => l.model.parent_purchase_line_uid == line.model.uid);
    }

    isChildLine(line: PurchaseLineRepository): boolean {
        return line.model.parent_purchase_line_uid != undefined;
    }

    addPaymentLine(
        paymentTypeId: number,
        date: Date,
        amount: number,
    ): PurchasePaymentRepository {

        const payment = new PurchasePaymentRepository({
            uid: uuidv4(),
            payment_type_id: paymentTypeId,
            date: date,
            amount: amount,
        })

        super.addPayment(payment);

        return payment;
    }
}