import StoreMedia from '@/models/storemedia';
import StoreWebhookEvent from '@/models/storewebhookevent';
import SlotTemplate from '@/models/slottemplate';
import StorePos from '@/models/storepos';

export default class Printer {
    [key: string]: any;

    public id?: number;
    public uid?: string;
    public name?: string;
    public active?: boolean = true;

    constructor(init?: Partial<Printer>) {
        Object.assign(this, init);
    }

    fromJson(init: any): Printer {
        for (const key in init) {
            try {
                switch (key) {
                    default:
                        this[key] = init[key];
                }
            } catch (err) { console.warn(`Printer fromJson conversion warning: ${key} is missing`) }
        }
        return this;
    }

    toJson(): any {
        return this;
    }
}