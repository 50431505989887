import Country from '@/models/country'
import BaseProvider from './baseprovider'

export default class CountryProvider extends BaseProvider {
    public async fetchCountries(payload?: {
        countryIds?: number[],
        countryActive?: boolean,
        vatCountryActive?: boolean
    }): Promise<Country[]> {
        let p: String = '';

        p = p.addParams("countryIds", payload?.countryIds, true);
        p = p.addParams("countryActive", [payload?.vatCountryActive])
        p = p.addParams("vatCountryActive", [payload?.vatCountryActive])
        p = p !== '' ? `?${p}` : '';

        const response = await (await this.getApiV2()).get(`/countries${p}`);

        return response.data.map((d: any) => new Country(d));
    }
}
