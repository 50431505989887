export default class SalesDetailsModel {
    [key: string]: any;
    store_id!: number;
    item_id?: number;
    unit_price!: number;
    quantity?: number;
    group_date?: Date;
    nb_discount?: number;
    total_discount?: number;
    total?: number;

    constructor(init: Partial<SalesDetailsModel> & Required<{store_id: number, unit_price: number}>) {
        Object.assign(this, init);
        if (init.group_date) this.group_date = new Date(init.group_date);
    }
}
