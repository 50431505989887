import BaseProvider from '@/providers/baseprovider';

export default class ErrorProvider extends BaseProvider {
    private errorMessage = (err: any): string | undefined => {
        if (err?.response?.status === 200) return;

        let r: string;
        if (err.response)
            r = `
                Error data: ${err.response.data}
                Error status: ${err.response.status}
                Error headers: ${err.response.headers}
            `;
        else if (err.request) r = `Error request: ${err.request}`;
        else r = `Error message: ${err.message}`;
        console.error(r);
        return r;
    }
    public async sendError(auth: any, error: any): Promise<string | undefined> {
        try {
            delete auth.picture_content;
            await (await this.getApiV2()).post(`/error/send`, { auth, error });
            return;
        } catch (err: any) { return this.errorMessage(err); }
    }
}
