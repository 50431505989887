import SaleLine from '@/models/saleline'
import Payment from '@/models/payment'
import { ConsumptionType } from '@/models/enums/consumptiontype.enum'

export default class Sale {
  [key: string]: any
  uid?: string
  id?: number
  number?: number
  date?: Date
  creation_date?: Date
  consumption_type?: ConsumptionType
  currency_id?: number
  total_vat_excluded?: number
  total_vat_included?: number
  fully_transferred?: boolean
  close_customer_account_document?: boolean
  journal_id?: number
  journal_name?: string
  flow_type?: number
  entity_id?: number
  entity_name?: string
  store_id?: number
  pos_id?: number
  pos_device_id?: string
  pos_name?: string
  global_user_id?: number
  global_user_name?: string
  invoice_customer_id?: number
  invoice_customer_name?: string
  invoice_customer_last_name?: string
  invoice_customer_first_name?: string
  invoice_customer_ask_invoice?: boolean
  is_system?: boolean
  fdm_transaction_timestamp?: Date
  fdm_transaction_receipt_counter?: string
  fdm_transaction_signature?: string
  fdm_transaction_plu_hash?: string
  fdm_transaction_control_module_id?: string
  fdm_transaction_vsc_number?: string
  fdm_transaction_serial_pos?: string
  cash_management_id?: number
  cash_management_creation_date?: Date
  reference?: string
  takeaway?: boolean
  price_list_id?: number
  fdm_event_type?: number
  synchronization_date?: Date
  modification_date?: Date
  print_number?: number
  easystore_origin_id?: number
  deadline?: Date
  notification_order_customer_name?: string
  notification_order_buzzer?: string
  notification_order_sms?: string
  notification_order_email?: string
  table_uid?: string
  table_number?: string
  guest_count?: number
  planning_date?: Date
  from_sale_header_id?: number
  note?: string
  slot_id?: number
  delivery_address_id?: number
  simple_customer_call_number?: string
  payment_provider_information?: string
  deposit?: boolean
  prefix_number?: string
  last_global_user_id_to_ask_service?: number
  customer_address_line?: string
  customer_address_line2?: string
  customer_zip_code?: string
  customer_city?: string
  customer_country_id?: number
  easystore_origin_name?: string
  quantity?: number
  transferred_quantity?: number
  total_amount?: number
  journal_backoffice?: boolean
  lines?: SaleLine[]
  payments?: Payment[]

  constructor(init?: Partial<Sale>) {
    Object.assign(this, init)
  }

  fromJson(init: any): Sale {
    for (const key in init) {
      try {
        switch (key) {
          case 'header':
            Object.keys(init[key]).forEach((headerKey: any, i: number) => {
              switch (headerKey) {
                case 'date':
                case 'creation_date':
                case 'fdm_transaction_timestamp':
                case 'cash_management_creation_date':
                case 'synchronization_date':
                case 'modification_date':
                case 'deadline':
                case 'planning_date':
                  this[headerKey] = init[key][headerKey]
                    ? new Date(init[key][headerKey])
                    : undefined
                  break
                default:
                  this[headerKey] = init[key][headerKey]
                  break
              }
            })
            break
          case 'lines':
          case 'payments':
            init[key].forEach((l: any, i: number) => {
              const n = key === 'lines' ? new SaleLine() : new Payment()
              if ((this[key] ?? []).length == 0) {
                this[key] = [n.fromJson(l)]
              } else {
                this[key]!.push(n.fromJson(l))
              }
            })
            break
          default:
            this[key] = init[key]
        }
      } catch (err) {
        console.warn(
          `Sale fromJson conversion warning: ${key} is missing ${err}`
        )
      }
    }
    return this
  }
}
