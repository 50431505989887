import { DocumentHeader, DocumentLine, } from "./document";

export abstract class ItemDocumentLine extends DocumentLine {
    item_uid?: string;
    description?: string;
    quantity?: number;
    unit_price?: number;
    total?: number;

    fromJson(init: any): DocumentLine {
        super.fromJson(init);

        this.item_uid = init["item_uid"] ?? undefined;
        this.description = init["description"] ?? undefined;
        this.quantity = init["quantity"] ?? undefined;
        this.unit_price = init["unit_price"] ?? undefined;
        this.total = init["total"] ?? undefined;

        return this;
    }
}

export abstract class ItemDocumentHeader extends DocumentHeader {

}