import BaseProvider from "./baseprovider"
import {PagedParams} from '@/models/paged/pagedparams';
import PagedActiveLicenses from '@/models/paged/licenses/pagedactivelicenses';
import PagedDemoLicenses from '@/models/paged/licenses/pageddemolicences';
import PagedBlockedLicenses from '@/models/paged/licenses/pagedblockedlicenses';
import PagedInactivesLicenses from '@/models/paged/licenses/pagedinactiveslicenses';
import PagedUnbindLicenses from '@/models/paged/licenses/pagedunbindlicenses';
import PagedEmailHistory from "@/models/paged/licenses/pagedemailhistory";
import PagedSMSHistory from "@/models/paged/licenses/pagedsmshistory";
import PagedActiveContracts from "@/models/paged/licenses/pagedactivecontracts";

export type LicenseReport =
    'ActiveLicenses' | 'BlockedLicenses' | 'DemoLicenses' | 'InactivesLicenses' |
    'UnbindLicenses' | 'EmailHistory' | 'SMSHistory' | 'ActiveContracts' ;

export default class LicenseProvider extends BaseProvider {

    private async fetchPagedLicenses(report: LicenseReport, pagedParams?: PagedParams){
        let params: String = '';
        params = params.addPagedParams(pagedParams);
        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`/licenses/${report}${params}`);

        return response.data;
    }

    public fetchPagedActiveLicenses = async (pagedParams?: PagedParams): Promise<PagedActiveLicenses> =>
        await this.fetchPagedLicenses('ActiveLicenses', pagedParams) as PagedActiveLicenses;
    public fetchPagedBlockedLicenses = async (pagedParams?: PagedParams): Promise<PagedBlockedLicenses> =>
        await this.fetchPagedLicenses('BlockedLicenses', pagedParams) as PagedBlockedLicenses;
    public fetchPagedDemoLicenses = async (pagedParams?: PagedParams): Promise<PagedDemoLicenses> =>
        await this.fetchPagedLicenses('DemoLicenses', pagedParams) as PagedDemoLicenses;
    public fetchPagedEmailHistory = async (pagedParams?: PagedParams): Promise<PagedEmailHistory> =>
        await this.fetchPagedLicenses('EmailHistory', pagedParams) as PagedEmailHistory;
    public fetchPagedInactivesLicenses = async (pagedParams?: PagedParams): Promise<PagedInactivesLicenses> =>
        await this.fetchPagedLicenses('InactivesLicenses', pagedParams) as PagedInactivesLicenses;
    public fetchPagedUnbindLicenses = async (pagedParams?: PagedParams): Promise<PagedUnbindLicenses> =>
        await this.fetchPagedLicenses('UnbindLicenses', pagedParams) as PagedUnbindLicenses;
    public fetchPagedSMSHistory = async (pagedParams?: PagedParams): Promise<PagedSMSHistory> =>
        await this.fetchPagedLicenses('SMSHistory', pagedParams) as PagedSMSHistory;
    public fetchPagedActiveContracts = async (pagedParams?: PagedParams): Promise<PagedActiveContracts> =>
        await this.fetchPagedLicenses('ActiveContracts', pagedParams) as PagedActiveContracts;

    public async unbindLicense(params: { license_code: string, device_id: string }) {
        const response = await (await this.getApiV2()).post(`/licenses/unbind`, params);

        return response.data;
    }

    public async disableDemoMode(params: { license_code: string }) {
        const response = await (await this.getApiV2()).post(`/licenses/disabledemomode`, params);

        return response.data;
    }

    /**
     * Fetch a paged resultset of licenses linked to specific entities
     * @param entityIds The entities which the licenses must be linked to
     * @param pagedParams The pagination parameters
     * @returns A paged resultset of licenses

    public async fetchPagedLicenses(
            entityIds?: number[],
            pagedParams?: PagedParams,
            blocked?: boolean,
            contract?: boolean,
            bound?: boolean): Promise<PagedLicenses> {
        let params: String = '';
        if (typeof entityIds !== 'undefined') params = params.addParams("entityIds", entityIds, true);
        if (typeof blocked !== 'undefined') params = params.addParams("blocked", [blocked], false);
        if (typeof contract !== 'undefined') params = params.addParams("contract", [contract], false);
        if (typeof bound !== 'undefined') params = params.addParams("bound", [bound], false);
        params = params.addPagedParams(pagedParams);
        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`Licenses${params}`);

        return new PagedLicenses(response.data);
    }

    public async unbindLicense(params: { license_code: string, device_id: string }) {
        const response = await (await this.getApiV2()).post(`/licenses/unbind`, params);

        return response.data;
    }

    /* public async fetchPagedLicensesCustomers(entityIds: number[], pagedParams?: PagedParams): Promise<PagedLicensesCustomers> {
        let params: String = '';
        params = params.addParams("entityIds", entityIds, true);
        params = params.addPagedParams(pagedParams);
        params = params !== '' ? `?${params}` : '';

        const response = await (await this.getApiV2()).get(`Licenses/Customers${params}`);

        return new PagedLicenses(response.data);
    } */
}